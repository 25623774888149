<template>
    <div>
        <v-row>
            <v-col>
                <div class="my-2">
                    <v-btn
                        block
                        color="#4c9aff"
                        dark
                        elevation="0"
                        @click.prevent="manualConfirmOrderItem"
                    >
                        <v-icon small class="mr-2">fa-hand-paper</v-icon>
                        Manually Confirm
                    </v-btn>
                </div>
                <div class="my-2">
                    <v-btn
                        block
                        color="#4c9aff"
                        dark
                        elevation="0"
                        @click="substituteItem.dialog = true"
                    >
                        <v-icon small class="mr-2">fa-sync</v-icon>
                        Substitute Item
                    </v-btn>
                </div>
            </v-col>
            <v-col>
                <div class="my-2">
                    <v-btn
                        block
                        color="#4c9aff"
                        dark
                        elevation="0"
                        @click="setRestockNeeded"
                    >
                        <v-icon small class="mr-2"
                            >fa-exclamation-circle</v-icon
                        >
                        Needs Ordered
                    </v-btn>
                </div>
                <div class="my-2">
                    <v-btn
                        block
                        color="#4c9aff"
                        dark
                        elevation="0"
                        v-show="isAllowedToSetOos"
                        @click="setOutOfStock"
                    >
                        <v-icon small class="mr-2">fa-times-circle</v-icon>
                        Out of Stock
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="substituteItem.dialog" persistent max-width="350">
            <v-card>
                <v-form
                    ref="substituteItemForm"
                    v-model="substituteItem.valid"
                    lazy-validation
                    class="px-4 py-2"
                >
                    <v-text-field
                        v-model="substituteItem.sku"
                        label="SKU"
                        :rules="substituteItem.requiredRule"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="substituteItem.upc"
                        label="UPC"
                        :rules="substituteItem.requiredRule"
                        required
                    ></v-text-field>
                </v-form>
                <v-card-actions class="px-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        color="gry"
                        text
                        @click="substituteItem.dialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn color="primary" @click="markItemAsSubstitute">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'PullItemActions',
    data() {
        return {
            substituteItem: {
                dialog: false,
                valid: true,
                requiredRule: [v => !!v || 'The field is required'],
                sku: '',
                upc: ''
            }
        };
    },
    computed: {
        ...mapState(['currentUser']),
        isAllowedToSetOos() {
            return this.currentUser.role.includes('oos_manager');
        }
    },
    methods: {
        manualConfirmOrderItem() {
            this.$emit('manualConfirmOrderItem');
        },
        setRestockNeeded() {
            this.$emit('setRestockNeeded');
        },
        setOutOfStock() {
            this.$emit('setOutOfStock');
        },
        markItemAsSubstitute() {
            if (this.$refs.substituteItemForm.validate()) {
                this.$emit('markItemAsSubstitute', {
                    sku: this.substituteItem.sku,
                    upc: this.substituteItem.upc
                });
                this.$refs.substituteItemForm.reset();
                this.substituteItem.dialog = false;
            }
        }
    }
};
</script>
