<template>
    <v-dialog
        v-model="show"
        :max-width="options.width"
        :style="{ zIndex: options.zIndex }"
        @keydown.esc="show = false"
    >
        <v-card flat>
            <v-date-picker v-model="date"></v-date-picker>
        </v-card>
        <v-card>
            <v-card-actions>
                <v-btn color="primary" @click="(show = false), selectDate()"
                    >Select</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: Boolean
    },
    data() {
        return {
            options: {
                color: 'primary',
                width: 290,
                zIndex: 200
            },
            date: new Date().toISOString().substr(0, 10)
        };
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        selectDate() {
            this.$emit('selectedDate', this.date);
        }
    }
};
</script>
