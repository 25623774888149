<template>
    <v-card width="100%" class="mx-auto">
        <v-card-title>Customer Notes</v-card-title>
        <v-alert
            v-show="alert.message != ''"
            color="primary"
            border="left"
            colored-border
            :type="alert.type"
            elevation="2"
        >
            {{ alert.message }}
        </v-alert>

        <v-form ref="searchNoteForm">
            <v-card-text>
                <v-text-field
                    label="Email"
                    v-model.trim="email"
                    :rules="[rules.required, rules.email]"
                />
            </v-card-text>
            <v-card-actions class="px-4 py-4">
                <v-btn
                    color="primary"
                    type="submit"
                    @click.prevent="searchCustomerNote"
                    >Search</v-btn
                >
                <v-btn
                    color="primary"
                    type="button"
                    @click="showAddNewCustomerNotePopup = true"
                    >Add New Customer Note</v-btn
                >
            </v-card-actions>
        </v-form>

        <v-dialog v-model="showUpdateCustomerNotePopup" max-width="600px">
            <v-card>
                <v-form ref="updateNoteForm">
                    <v-card-title>
                        <span class="text-h5"
                            >Update Customer Note for <i>{{ email }}</i></span
                        >
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-textarea
                                        outlined
                                        :rules="[rules.required]"
                                        label="Notes"
                                        v-model="searchedCustomerNote"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            type="button"
                            @click="showUpdateCustomerNotePopup = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            type="button"
                            @click="updateCustomerServiceNote"
                        >
                            Update Note
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showAddNewCustomerNotePopup" max-width="600px">
            <v-card>
                <v-form ref="addNewNoteForm">
                    <v-card-title>
                        <span class="text-h5">Add Customer Note</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Email"
                                        v-model.trim="addNewForm.email"
                                        :rules="[rules.required, rules.email]"
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        outlined
                                        :rules="[rules.required]"
                                        label="Notes"
                                        v-model="addNewForm.notes"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            type="button"
                            @click="showAddNewCustomerNotePopup = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            type="button"
                            @click="saveNewCustomerNote"
                        >
                            Save Note
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { customerNotes } from '@/data/index';

export default {
    data() {
        return {
            alert: {
                type: 'error',
                message: ''
            },
            email: '',
            searchedCustomerNote: '',
            rules: {
                required: value => !!value || 'The field is required.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Please enter a valid email.';
                }
            },
            showUpdateCustomerNotePopup: false,
            showAddNewCustomerNotePopup: false,
            addNewForm: {
                email: '',
                notes: ''
            }
        };
    },
    methods: {
        searchCustomerNote() {
            if (!this.$refs.searchNoteForm.validate()) {
                return false;
            }
            const self = this;
            self.alert.message = '';
            customerNotes
                .getCustomerNotes(self.email)
                .then(snapshot => {
                    if (!snapshot.exists) {
                        self.alert.type = 'error';
                        self.alert.message =
                            "We couldn't find any notes for the customer " +
                            self.email;
                        return;
                    }
                    self.searchedCustomerNote = snapshot.data().notes;
                    self.showUpdateCustomerNotePopup = true;
                })
                .catch(err => {
                    self.alert.type = 'error';
                    self.alert.message =
                        "There was a problem while fetching customer's notes.";
                    console.error(err);
                    self.showUpdateCustomerNotePopup = false;
                });
        },

        saveNewCustomerNote() {
            if (!this.$refs.addNewNoteForm.validate()) {
                return false;
            }
            const self = this;
            self.alert.message = '';
            customerNotes
                .saveNote(self.addNewForm)
                .then(() => {
                    self.alert.type = 'success';
                    self.alert.message = 'You have added a new note.';
                    self.showAddNewCustomerNotePopup = false;
                })
                .catch(err => {
                    self.alert.type = 'error';
                    self.alert.message =
                        'There was a problem while saving customer notes.';
                    console.error(err);
                    self.showAddNewCustomerNotePopup = false;
                });
        },

        updateCustomerServiceNote() {
            if (!this.$refs.updateNoteForm.validate()) {
                return false;
            }
            const self = this;
            self.alert.message = '';
            customerNotes
                .saveNote({
                    email: self.email,
                    notes: self.searchedCustomerNote
                })
                .then(() => {
                    self.alert.type = 'success';
                    self.alert.message = 'You have updated the note.';
                    self.showUpdateCustomerNotePopup = false;
                })
                .catch(err => {
                    self.alert.type = 'error';
                    self.alert.message =
                        'There was a problem while saving customer notes.';
                    console.error(err);
                    self.showUpdateCustomerNotePopup = false;
                });
        }
    }
};
</script>
