<template>
    <v-card width="100%" flat class="mx-auto">
        <v-card-title>
            <span>Items Flagged for Restock</span>
            <v-spacer></v-spacer>
            <v-btn light color="primary" @click="clearItems">Clear Items</v-btn>
        </v-card-title>
        <v-alert
            v-model="show_alert"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            elevation="2"
            dismissible
        >
            {{ alert_message }}
        </v-alert>
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="restockItems"
            item-key="uid"
            disable-sort
            disable-pagination
            hide-default-footer
            show-select
            class="elevation-2 mx-auto complete-table"
        >
            <template v-slot:item.qty="{ item }">
                {{ item.qtyNeeded }}
                <span v-if="item.sku && item.sku.includes('-case')">C</span>
            </template>
            <template v-slot:item.requestedAt="{ item }">
                {{ new Date(item.requested).toDateString() }}
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { db } from '@/firebase';
import * as data from '@/data/index';

export default {
    data() {
        return {
            show_alert: false,
            alert_message: '',
            headers: [
                {
                    text: 'Product',
                    align: 'start',
                    value: 'name'
                },
                { text: 'SKU', value: 'sku' },
                { text: 'Qty Needed', value: 'qty' },
                { text: 'Reporting Cart', value: 'cartId' },
                {
                    text: 'Restock Requested',
                    value: 'requestedAt'
                }
            ],
            restockItems: [],
            selected: []
        };
    },
    mounted() {
        this.loadRestockItems();
    },
    methods: {
        loadRestockItems() {
            this.$root.$dialogLoader.show('Loading report...', {});
            data.products.getRestockedItems().then(async snapshot => {
                const promises = snapshot.docs.map(async snap => {
                    let data = snap.data();
                    data.uid = snap.id;
                    data.name = await this.getProductName(data.sku);
                    return data;
                });
                this.restockItems = await Promise.all(promises);
                this.selected = [];
                this.$root.$dialogLoader.hide();
            });
        },
        async getProductName(sku) {
            if (sku == '') {
                return '';
            }
            const product = await data.products.getProductBySku(sku);
            return product && product.data && product.data.length
                ? product.data[0].name
                : '';
        },

        async clearItems() {
            if (!this.selected.length) {
                this.alert_message = 'No item is selected';
                this.show_alert = true;
                return;
            }
            this.alert_message = '';
            this.show_alert = false;

            this.$root.$dialogLoader.show('Clearing report...', {});
            const promises = this.selected.map(restockItem => {
                return db
                    .collection('restock_item')
                    .doc(restockItem.toString())
                    .delete();
            });
            await Promise.all(promises);
            this.$root.$dialogLoader.hide();
            this.loadRestockItems();
        }
    }
};
</script>
