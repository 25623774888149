<template>
    <v-dialog v-model="show" max-width="500">
        <v-card>
            <v-btn
                icon
                color="#4c9aff"
                class="place-top-right"
                @click="show = false"
            >
                <v-icon>fa-times</v-icon>
            </v-btn>
            <v-card-title class="headline" v-if="orderId">
                Take over order #{{ orderId }}?
            </v-card-title>

            <v-card-text class="px-3">
                <div class="text--primary">
                    Order #{{ orderId }} is currently assigned to
                    <strong>{{ assignedTo }}</strong
                    >.
                </div>
                <div class="text--primary">
                    Taking over the order will assign it to you and allow you to
                    pick this order.
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="#4c9aff" @click="reassign">
                    Take Over
                </v-btn>
                <v-btn text color="#4c9aff" @click="show = false">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped>
.place-top-right {
    position: absolute;
    top: 0;
    right: 0;
}
</style>

<script>
import { mapState } from 'vuex';
import * as data from '@/data/index';

export default {
    props: {
        value: Boolean,
        orderId: {
            type: Number
        },
        assignedTo: {
            type: String
        }
    },
    data() {
        return {
            orderHistory: []
        };
    },
    computed: {
        ...mapState(['users', 'currentUser']),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        reassign() {
            this.$store.dispatch('updateOrder', {
                orderId: this.orderId,
                data: { assigned: this.currentUser.uid }
            });
            data.orderHistory.save({
                orderId: this.orderId,
                action: 'assigned',
                description:
                    'Assigned order to ' + this.getSelectedUserDisplayName()
            });

            this.$router.push(`/pull/${this.orderId}`);
        },
        getSelectedUserDisplayName() {
            let self = this;
            let displayName = '';

            self.users.every(user => {
                if (user.id == self.selectedUser) {
                    displayName = user.displayName;
                    return false;
                }
                return true;
            });
            return displayName;
        }
    }
};
</script>
