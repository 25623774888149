<template>
    <v-card width="100%" flat class="mx-auto">
        <v-card-title>
            <span>Inventory Reports</span>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-alert
            v-show="error != ''"
            color="#4c9aff"
            border="left"   
            colored-border
            type="error"
            elevation="2"
            dismissible
        >
            {{ error }}
        </v-alert>
        <template>
            <v-data-table
                mobile-breakpoint="0"
                dense
                :headers="reportHeaders"
                :items="reports"
                class="elevation-2 mx-auto complete-table"
                :footer-props="{
                    itemsPerPageText : 'per page'
                }"
            >
                <template v-slot:item.actions="{ item }">
                    <v-btn
                        color="primary"
                        class="mr-2"
                        elevation="2"
                        x-small
                        @click="downloadReport(item.uid)"
                    >
                        <span class="d-none d-sm-inline">Download</span>
                        <v-icon class="d-sm-none" x-small>
                            fa-download
                        </v-icon>
                    </v-btn>
                    <v-btn
                        color="error"
                        elevation="2"
                        x-small
                        @click="downloadReport(item.uid)"
                    >                    
                        <v-icon x-small @click="deleteReport(item.uid)">
                            fa-trash
                        </v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </template>
    </v-card>
</template>

<script>
import * as data from '@/data/index';

export default {
    data() {
        return {
            error: '',
            expanded: [],
            singleExpand: false,
            reportHeaders: [
                {
                    text: 'Report',
                    sortable: false,
                    value: 'option',
                    width: '40%'
                },
                { text: 'Created At', sortable: false, value: 'created_at' },
                { text: 'Actions', value: 'actions', sortable: false, align: 'end', width: '35%' }
            ],
            reports: []
        };
    },
    mounted() {
        this.loadReports();
    },
    computed: {},
    methods: {
        loadReports() {
            let self = this;
            self.$root.$dialogLoader.show('Loading report...', {});
            data.inventoryReport
                .getReports()
                .then(querySnapshot => {
                    const reports = querySnapshot.docs.map(doc => {
                        let data = doc.data();
                        return {
                            option: data.option,
                            created_at: new Date(
                                data.created_at
                            ).toLocaleString(),
                            uid: doc.id
                        };
                    });
                    self.reports = reports;
                    self.$root.$dialogLoader.hide();
                })
                .catch(err => {
                    console.error(err);
                });
        },

        downloadReport(id) {
            let self = this;
            self.$root.$dialogLoader.show('Downloading report…', {});
            data.inventoryReport
                .getReport(id)
                .then(snapshot => {
                    const report = snapshot.data();
                    let csv = 'Option,UPC Scanned,SKU,QTY\n';
                    report.data.forEach(row => {
                        csv += `${row['option']},${row['upc']},${row['sku']},${row['qty']}`;
                        //csv += Object.values(row).join(',');
                        csv += '\n';
                    });

                    const anchor = document.createElement('a');
                    anchor.href =
                        'data:text/csv;charset=utf-8,' +
                        encodeURIComponent(csv);
                    anchor.target = '_blank';
                    anchor.download = 'inventory-report.csv';
                    anchor.click();
                    self.$root.$dialogLoader.hide();
                })
                .catch(err => {
                    self.error = 'There was a problem in fetching report.';
                    console.error(err);
                    self.$root.$dialogLoader.hide();
                });
        },

        deleteReport(id) {
            let self = this;
            self.$root.$dialogLoader.show('Deleting report…', {});
            data.inventoryReport
                .deleteReport(id)
                .then(() => {
                    self.$router.push({
                        name: 'Home'
                    });
                    self.$root.$dialogLoader.hide();
                })
                .catch(err => {
                    self.error = 'There was a problem in deleting report.';
                    console.error(err);
                    self.$root.$dialogLoader.hide();
                });
        }
    }
};
</script>
