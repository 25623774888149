<template>
    <div style="width: 100%">
        <v-alert
            v-model="show_alert"
            color="#4c9aff"
            border="left"
            colored-border
            type="warning"
            elevation="2"
            dismissible
        >
            {{ alert_message }}
        </v-alert>
        <template>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left"></th>
                            <th class="text-left">User</th>
                            <th class="text-left">Privileges</th>
                            <th class="text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, i) in users" :key="i">
                            <td class="pr-0">
                                <v-icon color="grey lighten-1" x-small
                                    >fa-user</v-icon
                                >
                            </td>
                            <td>
                                {{ user.displayName }}
                                <span>
                                    <br />
                                    <em class="grey--text lighten-3"
                                        >({{
                                            user.email.substring(
                                                0,
                                                user.email.indexOf('@')
                                            )
                                        }})</em
                                    >
                                </span>
                            </td>
                            <td>
                                <v-chip
                                    v-if="user.role"
                                    x-small
                                    color="primary"
                                >
                                    {{ user.role }}
                                </v-chip>
                            </td>
                            <td class="pr-0">
                                <v-btn
                                    @click="
                                        (resetPasswordForm.userId = user.id),
                                            (resetPasswordDialog = true)
                                    "
                                    color="secondary"
                                    small
                                >
                                    <v-icon x-small class="mr-2"
                                        >fa-wrench</v-icon
                                    >
                                    Reset
                                    <span class="d-none d-sm-flex"
                                        >Password</span
                                    >
                                </v-btn>
                                <v-btn
                                    class="ml-2"
                                    @click="openUserRoleUpdateDialog(user)"
                                    color="secondary"
                                    small
                                >
                                    <v-icon x-small class="mr-2"
                                        >fa-wrench</v-icon
                                    >
                                    Update Role
                                </v-btn>
                                <v-btn
                                    class="ml-2"
                                    @click="deleteUser(user.id)"
                                    color="secondary"
                                    small
                                >
                                    <v-icon x-small class="mr-2"
                                        >fa-trash</v-icon
                                    >
                                    Delete
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-btn @click="dialog = true" color="secondary" class="mt-5" block>
                <v-icon small class="mr-2">fa-user-plus</v-icon> Add User</v-btn
            >
        </template>

        <v-dialog v-model="dialog" width="500" fullscreen>
            <v-card>
                <v-form ref="addUserForm" @submit.prevent="addUser">
                    <v-card-title class="headline secondary white--text mb-5">
                        Add User
                    </v-card-title>
                    <div v-if="error" class="error--text px-4 py-4">
                        <div class="v-messages__message">
                            {{ error }}
                        </div>
                    </div>
                    <v-card-text>
                        <v-text-field
                            label="Name"
                            v-model.trim="addUserForm.name"
                            :rules="[rules.required]"
                        />
                        <v-text-field
                            label="Username"
                            v-model.trim="addUserForm.username"
                            :rules="[rules.required, rules.allowedCharacters]"
                        />
                        <v-text-field
                            :type="showPassword ? 'text' : 'password'"
                            label="Password"
                            :append-icon="
                                showPassword ? 'fa-eye' : 'fa-eye-slash'
                            "
                            @click:append="showPassword = !showPassword"
                            v-model.trim="addUserForm.password"
                            :rules="[rules.required, rules.minimumSix]"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-col cols="6">
                            <v-btn block @click="dialog = false">
                                Cancel
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="secondary" block type="submit">
                                Add User
                            </v-btn>
                        </v-col>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="resetPasswordDialog" width="500" fullscreen>
            <v-card>
                <v-form ref="resetPasswordForm" @submit.prevent="resetPassword">
                    <v-card-title class="headline secondary white--text mb-5">
                        Reset User Password
                    </v-card-title>
                    <div v-if="error" class="error--text px-4 py-4">
                        <div class="v-messages__message">
                            {{ error }}
                        </div>
                    </div>
                    <v-card-text>
                        <v-text-field
                            :type="showPassword ? 'text' : 'password'"
                            label="Your Password"
                            prepend-icon="fa-lock"
                            :append-icon="
                                showPassword ? 'fa-eye' : 'fa-eye-slash'
                            "
                            @click:append="showPassword = !showPassword"
                            v-model.trim="resetPasswordForm.newPassword"
                            :rules="[rules.required, rules.minimumSix]"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-col cols="6">
                            <v-btn block @click="resetPasswordDialog = false">
                                Cancel
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="secondary" block type="submit">
                                Reset
                            </v-btn>
                        </v-col>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="updateRoleDialog" width="500" persistent>
            <v-card>
                <v-form ref="updateRoleForm" @submit.prevent="updateRole">
                    <v-card-title class="headline secondary white--text mb-5">
                        Update User Role
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                            label="Role"
                            v-model.trim="updateUserRoleForm.role"
                            :rules="[rules.required]"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-col cols="6">
                            <v-btn block @click="updateRoleDialog = false">
                                Cancel
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="secondary" block type="submit">
                                Update
                            </v-btn>
                        </v-col>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { users } from '@/data/index';
import { mapState } from 'vuex';

export default {
    name: 'Admin',
    data() {
        return {
            dialog: false,
            addUserForm: {
                name: '',
                username: '',
                password: ''
            },
            rules: {
                required: value => !!value || 'Required.',
                allowedCharacters: value => {
                    const pattern = /^[a-z0-9-\\._]+$/;
                    return (
                        pattern.test(value) ||
                        'Please use only letters (a-z), numbers (0-9), underscore(_), Hyphen(-) or Dot(.) in this field.).'
                    );
                },
                minimumSix: value => {
                    const pattern = /^.{6,35}$/;
                    return (
                        pattern.test(value) ||
                        'Please enter 6 or more characters.'
                    );
                }
            },
            show_alert: false,
            alert_message: '',
            error: null,
            resetPasswordDialog: false,
            showPassword: false,
            resetPasswordForm: {
                userId: '',
                newPassword: ''
            },
            updateRoleDialog: false,
            updateUserRoleForm: {
                userId: '',
                role: ''
            }
        };
    },
    computed: {
        ...mapState(['users'])
    },
    watch: {
        resetPasswordDialog(newValue) {
            if (newValue == false) {
                this.resetPasswordForm.userId = '';
            }
        }
    },
    methods: {
        async addUser() {
            if (this.$refs.addUserForm.validate()) {
                let username = this.addUserForm.username;
                if (!this.isEmailEntered(username)) {
                    const emailSuffix = await users.getEmailSuffix();
                    username = username + emailSuffix;
                }
                this.$store.dispatch('signup', {
                    name: this.addUserForm.name,
                    email: username,
                    password: this.addUserForm.password
                });
                this.alert_message = 'The user has been created.';
                this.show_alert = true;
                this.dialog = false;
            }
        },

        isEmailEntered(username) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(username);
        },

        resetPassword() {
            let self = this;
            if (this.$refs.resetPasswordForm.validate()) {
                self.$root.$dialogLoader.show('updating…', {});
                const payload = {
                    uid: this.resetPasswordForm.userId,
                    password: this.resetPasswordForm.newPassword
                };
                this.$store
                    .dispatch('updatePassword', payload)
                    .then(response => response.json())
                    .then(data => {
                        if (data.error) {
                            this.error = data.error;
                        } else {
                            this.alert_message = data.message;
                            this.show_alert = true;
                            this.resetPasswordDialog = false;
                        }
                        self.$root.$dialogLoader.hide();
                    });
            }
        },

        async deleteUser(uid) {
            let self = this;
            if (
                await self.$root.$confirmDialog.open(
                    null,
                    'Are you sure you want to delete the user?',
                    {}
                )
            ) {
                self.$root.$dialogLoader.show('Deleting...', {});
                self.$store
                    .dispatch('deleteUser', uid)
                    .then(response => response.json())
                    .then(data => {
                        if (data.error) {
                            self.alert_message = data.error;
                        } else {
                            self.alert_message = data.message;
                        }
                        self.show_alert = true;
                        self.$root.$dialogLoader.hide();
                    });
            }
        },

        openUserRoleUpdateDialog(user) {
            this.updateUserRoleForm.userId = user.id;
            this.updateUserRoleForm.role = user.role;
            this.updateRoleDialog = true;
        },

        updateRole() {
            let self = this;
            if (self.$refs.updateRoleForm.validate()) {
                self.$root.$dialogLoader.show('Updating Role...', {});
                self.$store
                    .dispatch('updateUserRole', self.updateUserRoleForm)
                    .then(() => {
                        self.updateRoleDialog = false;
                        self.$root.$dialogLoader.hide();
                    })
                    .catch(error => {
                        self.error = error;
                        self.updateRoleDialog = false;
                    });
            }
        }
    }
};
</script>
