var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"width":"100%","flat":""}},[_c('v-card-title',[_c('span',[_vm._v("Items Flagged Out of Stock")]),_c('v-spacer'),_c('v-btn',{attrs:{"light":"","color":"primary"},on:{"click":_vm.clearItems}},[_vm._v("Clear Items")])],1),_c('v-alert',{attrs:{"color":"#4c9aff","border":"left","colored-border":"","type":"error","elevation":"2","dismissible":""},model:{value:(_vm.show_alert),callback:function ($$v) {_vm.show_alert=$$v},expression:"show_alert"}},[_vm._v(" "+_vm._s(_vm.alert_message)+" ")]),_c('v-data-table',{staticClass:"elevation-2 mx-auto complete-table",attrs:{"headers":_vm.headers,"disable-sort":"","items":_vm.outOfStockItems,"item-key":"uid","disable-pagination":"","hide-default-footer":"","show-select":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderItem ? item.orderItem.name : '')+" ")]}},{key:"item.sku",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderItem ? item.orderItem.sku : '')+" ")]}},{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderItem ? item.orderItem.quantity : '')+" "),(
                    item.orderItem && item.orderItem.sku.includes('-case')
                )?_c('span',[_vm._v(" Case")]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }