<template>
    <v-card>
        <v-alert
            v-model="showError"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            class="mb-0"
        >
            {{ error }}
        </v-alert>
    </v-card>
</template>

<script>
import * as data from '@/data/index';
import { jsPDF } from 'jspdf';

export default {
    data() {
        return {
            order: {},
            showError: false,
            error: '',
            page: {
                size: {
                    width: 11,
                    height: 8.5
                },
                margins: {
                    top: 2.16,
                    right: 2.75,
                    bottom: 1,
                    left: 0.81
                }
            }
        };
    },
    mounted() {
        const self = this;
        self.$root.$dialogLoader.show('Preparing PDF for download..', {});
        data.orders
            .getOrder(self.$route.params.id)
            .then(querySnapshot => {
                self.order = querySnapshot.data();
                if (!data.orderGiftMessage.hasGiftMessage(self.order)) {
                    self.error = 'We can not find gift message to print.';
                    self.showError = true;
                } else {
                    self.createPdf();
                }
                self.$root.$dialogLoader.hide();
            })
            .catch(function(error) {
                console.log(error);
                self.error = 'Order not found for the given ID';
                self.showError = true;
                self.$root.$dialogLoader.hide();
            });
    },
    methods: {
        createPdf() {
            const doc = new jsPDF({
                orientation: 'landscape',
                unit: 'in',
                format: [this.page.size.width, this.page.size.height]
            });
            doc.setFont('Times', 'bolditalic');
            doc.setFontSize(16);
            doc.text(
                this.order.order.id.toString(),
                doc.internal.pageSize.getWidth() - this.page.margins.right,
                this.page.margins.top + 0.55,
                { align: 'right' }
            );
            doc.text(
                data.orderGiftMessage.getGiftMessage(this.order),
                (doc.internal.pageSize.getWidth() - this.page.margins.right) /
                    2,
                this.page.margins.top + 1.38,
                { align: 'center' }
            );
            doc.save(this.order.order.id + '_gift_message.pdf');
        }
    }
};
</script>
