<template>
    <v-card width="100%" flat class="mx-auto">
        <v-card-title>
            <span>Items Flagged Out of Stock</span>
            <v-spacer></v-spacer>
            <v-btn light color="primary" @click="clearItems">Clear Items</v-btn>
        </v-card-title>
        <v-alert
            v-model="show_alert"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            elevation="2"
            dismissible
        >
            {{ alert_message }}
        </v-alert>

        <v-data-table
            v-model="selected"
            :headers="headers"
            disable-sort
            :items="outOfStockItems"
            item-key="uid"
            disable-pagination
            hide-default-footer
            show-select
            class="elevation-2 mx-auto complete-table"
        >
            <template v-slot:item.name="{ item }">
                {{ item.orderItem ? item.orderItem.name : '' }}
            </template>

            <template v-slot:item.sku="{ item }">
                {{ item.orderItem ? item.orderItem.sku : '' }}
            </template>
            <template v-slot:item.qty="{ item }">
                {{ item.orderItem ? item.orderItem.quantity : '' }}
                <span
                    v-if="
                        item.orderItem && item.orderItem.sku.includes('-case')
                    "
                >
                    Case</span
                >
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import { db } from '@/firebase';
import * as data from '@/data/index';

export default {
    data() {
        return {
            show_alert: false,
            alert_message: '',

            outOfStockItems: [],
            selected: [],
            headers: [
                {
                    text: 'Name',
                    align: 'start',
                    value: 'name'
                },
                { text: 'SKU', value: 'sku' },
                { text: 'Qty Needed', value: 'qty' },
                { text: 'Reporting Cart', value: 'cartId' },
                { text: 'Order Number', value: 'orderId' }
            ]
        };
    },
    mounted() {
        this.loadOutOfStockItems();
    },
    methods: {
        loadOutOfStockItems() {
            this.$root.$dialogLoader.show('Loading report...', {});
            data.products.getOutOfStockItems().then(async snapshot => {
                const promises = snapshot.docs.map(async snap => {
                    let data = snap.data();
                    data.uid = snap.id;
                    data.orderId = snap.id.split('-')[1];
                    data.cartId = await this.getCartId(data.orderId);
                    return data;
                });
                this.outOfStockItems = await Promise.all(promises);
                this.selected = [];
                this.$root.$dialogLoader.hide();
            });
        },
        async getCartId(orderId) {
            return await db
                .collection('orders')
                .doc(orderId.toString())
                .get()
                .then(snap => {
                    return snap.data().verification.get();
                })
                .then(snap => {
                    return snap.data().cartId;
                });
        },

        async clearItems() {
            if (!this.selected.length) {
                this.alert_message = 'No item is selected';
                this.show_alert = true;
                return;
            }
            this.alert_message = '';
            this.show_alert = false;

            this.$root.$dialogLoader.show('Clearing report...', {});
            const promises = this.selected.map(verificationItem => {
                this.markParentItemAsIncomplete(verificationItem);
                return db
                    .collection('verification_item')
                    .doc(verificationItem.uid.toString())
                    .update({ status: 'incomplete' });
            });
            await Promise.all(promises);
            this.$root.$dialogLoader.hide();
            this.loadOutOfStockItems();
        },

        markParentItemAsIncomplete(item) {
            if (item.orderItem && item.orderItem.parent_order_product_id) {
                const orderId = item.uid.split('-')[1];
                this.$store.dispatch('updateVerificationItem', {
                    orderId: orderId,
                    itemId: item.orderItem.parent_order_product_id,
                    data: {
                        status: 'incomplete'
                    }
                });
            }
        }
    }
};
</script>
