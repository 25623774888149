<template>
    <v-row no-gutters align="center">
        <v-col>
            <v-card class="order-top" elevation="0" ref="pageMain" id="canvas">
                <v-alert
                    v-model="show_alert"
                    color="secondary"
                    border="left"
                    colored-border
                    type="error"
                    elevation="2"
                    dismissible
                >
                    {{ alert_message }}
                </v-alert>
                <ScannableInput
                    :active="true"
                    :debug="false"
                    @scan="scan"
                ></ScannableInput>
                <v-btn
                    dark
                    color="#4c9aff"
                    class="float-right"
                    @click="showShipStationBarcode"
                    >Show Ship Station Barcode</v-btn
                >
                <span class="clearfix"></span>
                <v-card-text
                    class="text-center"
                    v-if="currentOrder && currentOrder.order"
                >
                    <h2 class="title mb-4">Order ID: {{ orderId }}</h2>
                    <barcode
                        v-bind:value="barcodeText"
                        format="CODE128"
                        display-value="false"
                        height="50"
                    >
                    </barcode>
                </v-card-text>

                <v-card elevation="0" class="mb-4">
                    <v-card-text class="text-center">
                        <v-row>
                            <v-col>
                                <v-btn
                                    block
                                    color="#4c9aff"
                                    dark
                                    elevation="0"
                                    @click="finishOrder"
                                >
                                    <v-icon small class="mr-2">fa-check</v-icon>
                                    Mark Order Shipped
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn
                                    block
                                    color="#4c9aff"
                                    dark
                                    elevation="0"
                                    @click="showDatePicker = true"
                                >
                                    <span class="badge-icon">
                                        <v-icon small class="mr-2"
                                            >fa-truck</v-icon
                                        >
                                        <v-icon
                                            class="badge"
                                            color="secondary darken-3"
                                            x-small
                                            >fa-clock</v-icon
                                        >
                                    </span>
                                    Ship Later
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card>
        </v-col>
        <DatePicker
            v-model="showDatePicker"
            @selectedDate="setShipDate"
        ></DatePicker>
    </v-row>
</template>

<style scoped>
.clearfix::after {
    content: '';
    clear: both;
    display: table;
}
</style>
<script>
import { mapState } from 'vuex';
import * as data from '@/data/index';
import DatePicker from '@/components/DatePicker';
import VueBarcode from 'vue-barcode';
import ScannableInput from '@/components/ScannableInput';

export default {
    data() {
        return {
            showDatePicker: false,
            currentOrder: {},
            alert_message: '',
            show_alert: false,
            barcodeText: ''
        };
    },
    computed: {
        ...mapState(['currentUser']),
        orderId() {
            return this.$route.params.id;
        }
    },
    components: {
        DatePicker,
        barcode: VueBarcode,
        ScannableInput
    },
    beforeCreate() {
        let self = this;
        self.$root.$dialogLoader.show('Creating Verification Check…', {});
        data.orders
            .getOrder(self.$route.params.id)
            .then(async querySnapshot => {
                this.currentOrder = querySnapshot.data();
                self.$store
                    .dispatch('assignCurrentOrder', this.currentOrder.order.id)
                    .then(
                        async () => {
                            if (
                                this.currentOrder.internalStatus !=
                                'needs-shipped'
                            ) {
                                self.redirectToPage('Pull', {
                                    id: self.$route.params.id
                                });
                            } else {
                                this.barcodeText = this.orderId;
                            }
                            self.focusOnPage();
                            self.$root.$dialogLoader.hide();
                        },
                        error => {
                            console.log(error);
                            self.$root.$dialogLoader.hide();
                            self.redirectToPage('Home', {
                                show_alert: true,
                                alert_message:
                                    'There was a problem getting order information'
                            });
                        }
                    );
            })
            .catch(function(error) {
                console.log('order not found: ', error);
                self.$root.$dialogLoader.hide();
                self.redirectToPage('Home', {
                    show_alert: true,
                    alert_message:
                        'There was a problem getting order information'
                });
            });
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        async $route(to, from) {
            this.$root.$dialogLoader.show('Updating Barcode', {});
            this.barcodeText = this.orderId;
            this.focusOnPage();
            this.$root.$dialogLoader.hide();
        }
    },
    methods: {
        redirectToPage(page, params = {}) {
            this.$router.push({
                name: page,
                params: params
            });
        },

        finishOrder() {
            this.$store.dispatch('updateOrder', {
                orderId: this.orderId,
                data: {
                    internalStatus: 'complete',
                    assigned: null
                }
            });
            this.$store.dispatch('updateVerification', {
                orderId: this.orderId,
                data: { cartId: '' }
            });
            data.orderHistory.save({
                orderId: this.orderId,
                action: 'completed',
                description:
                    'Order has been completed by ' +
                    this.currentUser.displayName
            });
            this.redirectToPage('Home');
        },

        setShipDate(date) {
            this.$store.dispatch('updateOrder', {
                orderId: this.orderId,
                data: {
                    internalStatus: 'ship-later',
                    assigned: null,
                    delay: {
                        date: date,
                        user: this.currentUser.uid
                    }
                }
            });
            data.orderHistory.save({
                orderId: this.orderId,
                action: 'ship-later',
                description:
                    'Set shipping date to ' +
                    date +
                    ' by ' +
                    this.currentUser.displayName
            });
        },

        scan(response) {
            this.alert_message = '';
            this.show_alert = false;

            let self = this;
            self.$root.$dialogLoader.show('Finding the order…', {});
            data.verifications.getByCart(response.input).then(
                querySnapshot => {
                    if (querySnapshot.size) {
                        let verifications = querySnapshot.docs.map(doc =>
                            doc.data()
                        );
                        let verification = verifications[0];
                        verification.order.get().then(snap => {
                            let order = snap.data();
                            if (order.order.id == this.orderId) {
                                self.alert_message =
                                    'You scanned the current cartId.';
                                self.show_alert = true;
                                self.$root.$dialogLoader.hide();
                                return;
                            }
                            if (order.internalStatus == 'needs-shipped') {
                                this.$router.push({
                                    name: 'OrderBarcodeScan',
                                    params: { id: order.order.id }
                                });
                            } else {
                                if (
                                    order.is_locked &&
                                    order.is_locked === true
                                ) {
                                    this.$router.push({
                                        name: 'Home',
                                        params: {
                                            alert_message: `Orders (${order.order.id}) is currently locked.`,
                                            show_alert: true
                                        }
                                    });
                                } else {
                                    this.$router.push({
                                        name: 'Pull',
                                        params: { id: order.order.id }
                                    });
                                }
                            }
                            self.$root.$dialogLoader.hide();
                        });
                    } else {
                        this.alert_message =
                            "We can't find an order matching the barcode.";
                        this.show_alert = true;
                        self.$root.$dialogLoader.hide();
                    }
                },
                error => {
                    this.alert_message =
                        'There was a problem finding the order matching the barcode.';
                    this.show_alert = true;
                    console.error(error);
                    self.$root.$dialogLoader.hide();
                }
            );
        },

        focusOnPage() {
            this.$refs.pageMain.$el.click();
            let e = new Event('touchstart');
            this.$refs.pageMain.$el.dispatchEvent(e);
        },

        showShipStationBarcode() {
            this.$router.push({
                name: 'OrderShipStationBarcode',
                params: { orderId: this.orderId }
            });
        }
    }
};
</script>
