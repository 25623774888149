<template>
    <v-row no-gutters align="center">
        <v-col>
            <v-card class="order-top" elevation="0" ref="pageMain" id="canvas">
                <v-alert
                    v-model="show_alert"
                    color="secondary"
                    border="left"
                    colored-border
                    type="error"
                    elevation="2"
                    dismissible
                >
                    {{ alert_message }}
                </v-alert>
                <ScannableInput
                    :active="true"
                    :debug="false"
                    @scan="scan"
                ></ScannableInput>
                <v-card-text class="text-center">
                    <h2 class="title mb-4">Order ID: {{ orderId }}</h2>

                    <barcode
                        v-bind:value="barcodeTextWithShipStation"
                        format="CODE128"
                        display-value="false"
                        height="50"
                    >
                    </barcode>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import * as data from '@/data/index';

import VueBarcode from 'vue-barcode';
import * as Config from '@/data/config';
import ScannableInput from '@/components/ScannableInput';

export default {
    props: {
        orderId: { required: true }
    },
    data() {
        return {
            alert_message: '',
            show_alert: false,

            barcodeTextWithShipStation: ''
        };
    },
    components: {
        barcode: VueBarcode,
        ScannableInput
    },
    beforeCreate() {
        let self = this;
        self.$root.$dialogLoader.show('Loading Barcode…', {});
        Config.getShipstationBarcodePrefix()
            .then(shipStationPrefix => {
                if (shipStationPrefix === '') {
                    throw 'Ship-station barcode prefix not found.';
                }
                this.barcodeTextWithShipStation =
                    shipStationPrefix + this.orderId;
                self.focusOnPage();
                self.$root.$dialogLoader.hide();
            })
            .catch(function(error) {
                self.$root.$dialogLoader.hide();
                self.redirectToPage('Home', {
                    show_alert: true,
                    alert_message: error
                });
            });
    },
    methods: {
        redirectToPage(page, params = {}) {
            this.$router.push({
                name: page,
                params: params
            });
        },

        scan(response) {
            this.alert_message = '';
            this.show_alert = false;

            let self = this;
            self.$root.$dialogLoader.show('Finding the order…', {});
            data.verifications.getByCart(response.input).then(
                querySnapshot => {
                    if (querySnapshot.size) {
                        let verifications = querySnapshot.docs.map(doc =>
                            doc.data()
                        );
                        let verification = verifications[0];
                        verification.order.get().then(snap => {
                            let order = snap.data();
                            if (order.order.id == this.orderId) {
                                self.alert_message =
                                    'You scanned the current cartId.';
                                self.show_alert = true;
                                self.$root.$dialogLoader.hide();
                                return;
                            }
                            if (order.internalStatus == 'needs-shipped') {
                                this.$router.push({
                                    name: 'OrderBarcodeScan',
                                    params: { id: order.order.id }
                                });
                            } else {
                                if (
                                    order.is_locked &&
                                    order.is_locked === true
                                ) {
                                    this.$router.push({
                                        name: 'Home',
                                        params: {
                                            alert_message: `Orders (${order.order.id}) is currently locked.`,
                                            show_alert: true
                                        }
                                    });
                                } else {
                                    this.$router.push({
                                        name: 'Pull',
                                        params: { id: order.order.id }
                                    });
                                }
                            }
                            self.$root.$dialogLoader.hide();
                        });
                    } else {
                        this.alert_message =
                            "We can't find an order matching the barcode.";
                        this.show_alert = true;
                        self.$root.$dialogLoader.hide();
                    }
                },
                error => {
                    this.alert_message =
                        'There was a problem finding the order matching the barcode.';
                    this.show_alert = true;
                    console.error(error);
                    self.$root.$dialogLoader.hide();
                }
            );
        },

        focusOnPage() {
            this.$refs.pageMain.$el.click();
            let e = new Event('touchstart');
            this.$refs.pageMain.$el.dispatchEvent(e);
        }
    }
};
</script>
