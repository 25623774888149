<template>
    <v-dialog v-model="show">
        <v-card>
            <v-btn
                icon
                color="secondary"
                class="place-top-right"
                @click="show = false"
            >
                <v-icon>fa-times</v-icon>
            </v-btn>
            <v-card-title class="headline" v-if="orderId">
                Order #{{ orderId }}
            </v-card-title>

            <v-card-text v-if="orderHistory.length" class="px-3">
                <v-timeline align-top dense>
                    <v-timeline-item
                        v-for="(item, index) in orderHistory"
                        :key="index"
                        color="primary"
                        small
                    >
                        <div>
                            <div>
                                {{ item.description }}
                            </div>
                            <div class="font-weight-normal">
                                <strong>{{ item.user }}</strong> @{{
                                    new Date(item.created).toLocaleString()
                                }}
                            </div>
                        </div>
                    </v-timeline-item>
                </v-timeline>
            </v-card-text>
            <v-card-text v-else
                ><p>We found no history for this order.</p></v-card-text
            >
        </v-card>
    </v-dialog>
</template>

<style scoped>
.place-top-right {
    position: absolute;
    top: 0;
    right: 0;
}
</style>

<script>
import * as data from '@/data/index';

export default {
    props: {
        value: Boolean,
        orderId: {
            type: Number
        }
    },
    data() {
        return {
            orderHistory: []
        };
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    created() {
        data.orderHistory.get(this.orderId).then(querySnapshot => {
            this.orderHistory = querySnapshot.docs.map(doc => doc.data());
        });
    }
};
</script>
