<template
    ><v-row no-gutters align="center">
        <v-col>
            <v-card elevation="0">
                <v-alert
                    v-show="error !== ''"
                    color="#4c9aff"
                    border="left"
                    colored-border
                    type="error"
                    elevation="2"
                    dismissible
                >
                    {{ error }}
                </v-alert>
                <ScannableInput :active="true" :debug="false" @scan="scan" />
                <v-card
                    elevation="0"
                    class="text-center pb-3 overflow-auto"
                    v-if="canShowItems"
                >
                    <h2 class="title float-left">Order ID: {{ orderId }}</h2>
                    <h3 class="subtitle float-right">
                        <v-icon
                            class="align-baseline"
                            color="secondary lighten-1"
                            >fa-barcode</v-icon
                        >
                        {{
                            this.currentOrder &&
                            this.currentOrder.verification &&
                            this.currentOrder.verification.cartId != ''
                                ? this.currentOrder.verification.cartId
                                : 'Something went wrong.'
                        }}
                    </h3>
                </v-card>
                <v-card
                    elevation="0"
                    class="text-center pb-3 overflow-auto"
                    v-else
                >
                    <h2 class="title">Order ID: {{ orderId }}</h2>
                    <h3 class="subtitle">
                        <v-icon
                            class="align-baseline"
                            color="secondary lighten-1"
                            >fa-barcode</v-icon
                        >
                        {{
                            this.currentOrder &&
                            this.currentOrder.verification &&
                            this.currentOrder.verification.cartId != ''
                                ? this.currentOrder.verification.cartId
                                : 'Scan a Cart to begin this Order.'
                        }}
                    </h3>
                </v-card>

                <div v-show="canShowItems">
                    <v-card
                        v-for="(item, idx) in batchOrdersItems"
                        :key="idx"
                        elevation="3"
                        v-show="idx === currentIncompleteItemIndex"
                    >
                        <v-card-text class="text-center">
                            <h3>
                                {{ item.orderItem.name }}
                            </h3>
                            <v-img
                                class="ma-auto mt-2 mb-4 item-image"
                                :style="{
                                    backgroundImage: item.image
                                        ? 'url(' + item.image + ')'
                                        : 'url(' + placeholderPath + ')'
                                }"
                                max-width="200"
                                max-height="200"
                                contain
                            />
                            <v-row>
                                <v-simple-table
                                    class="text-left fullwidth"
                                    dense
                                    s
                                    v-if="currentOrder && currentOrder.order"
                                >
                                    <template v-slot:default>
                                        <tbody>
                                            <tr
                                                v-if="
                                                    currentOrder.is_perishable &&
                                                        currentOrder.is_perishable ===
                                                            true
                                                "
                                            >
                                                <td>Perishable</td>
                                                <td>Yes</td>
                                            </tr>
                                            <tr
                                                v-if="item.orderItem.quantity"
                                                :class="{
                                                    highlight: item.orderItem.sku.includes(
                                                        '-case'
                                                    )
                                                }"
                                            >
                                                <th>Qty</th>
                                                <td>
                                                    {{
                                                        item.orderItem.quantity
                                                    }}
                                                    <span
                                                        v-if="
                                                            item.orderItem.sku.includes(
                                                                '-case'
                                                            )
                                                        "
                                                    >
                                                        Case</span
                                                    >
                                                </td>
                                            </tr>
                                            <tr v-if="item.orderItem.sku">
                                                <th>SKU</th>
                                                <td>
                                                    {{ item.orderItem.sku }}
                                                </td>
                                            </tr>
                                            <tr v-if="item.location">
                                                <th>Location</th>
                                                <td>{{ item.location }}</td>
                                            </tr>
                                            <tr v-if="item.barcode">
                                                <th>UPC</th>
                                                <td>
                                                    {{ item.barcode }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-row>
                            <div v-if="isItemFromExternalOrder">
                                <v-row
                                    class="py-2"
                                    v-show="item.orderItem.product_id == 0"
                                    ><v-col
                                        ><h2>
                                            This product is from an external
                                            order, so it may not be scannable.
                                        </h2></v-col
                                    ></v-row
                                >
                                <v-row>
                                    <v-col
                                        ><div class="my-2">
                                            <v-btn
                                                block
                                                color="#4c9aff"
                                                dark
                                                elevation="0"
                                                @click="completeWithoutScan"
                                            >
                                                Complete Without Scan
                                            </v-btn>
                                        </div></v-col
                                    >
                                </v-row>
                            </div>
                            <div v-else>
                                <PullItemActions
                                    @manualConfirmOrderItem="
                                        manualConfirmOrderItem
                                    "
                                    @setRestockNeeded="setRestockNeeded"
                                    @setOutOfStock="setOutOfStock"
                                    @markItemAsSubstitute="markItemAsSubstitute"
                                ></PullItemActions>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-card>
        </v-col>
        <div></div></v-row
></template>

<style scoped>
.item-image {
    width: 300px;
    height: 300px;
    background-repeat: no-repeat;
    background-size: contain;
}

.highlight {
    background-color: #fff300;
    padding: 0px 5px;
}
</style>

<script>
import { mapState } from 'vuex';
import * as data from '@/data/index';
import ScannableInput from '@/components/ScannableInput';
import PullItemActions from '@/components/PullItemActions';

export default {
    name: 'MultiOrderPickup-Pulling',
    data() {
        return {
            placeholderPath: require('@/assets/placeholder.png'),
            error: '',
            batchOrders: [],
            currentOrder: {},
            currentOrderIndex: 0,
            currentOrderItems: [],
            currentIncompleteItem: {},
            currentIncompleteItemIndex: 0,
            hasRestockItem: false,
            restockItemIds: [],
            batchOrdersItems: [],
            scannedItems: []
        };
    },
    components: {
        ScannableInput,
        PullItemActions
    },
    computed: {
        ...mapState(['currentUser', 'currentBatchOrders']),
        orderId() {
            if (this.currentOrder && this.currentOrder.order) {
                return this.currentOrder.order.id;
            }
            return '';
        },
        canShowItems() {
            return !!(
                this.batchOrdersItems &&
                this.batchOrdersItems.length > 0 &&
                this.currentOrder &&
                this.currentOrder.verification &&
                this.currentOrder.verification.cartId != ''
            );
        },
        isItemFromExternalOrder() {
            return (
                this.currentIncompleteItem &&
                this.currentIncompleteItem.orderItem &&
                this.currentIncompleteItem.orderItem.product_id == 0
            );
        }
    },
    mounted() {
        const self = this;

        if (self.currentBatchOrders.length < 1) {
            self.redirectToHomePage('There were no orders in the batch.');
            return false;
        }

        self.$root.$dialogLoader.show('Preparing Orders for Pulling...', {});

        self.loadBatchOrders()
            .then(() => {
                self.$root.$dialogLoader.hide();
                self.claimBatchOrders();
            })
            .then(() => {
                self.scanOrdersCartId();
            });
    },
    methods: {
        redirectToHomePage(alertMessage) {
            this.$router.push({
                name: 'Home',
                params: {
                    show_alert: true,
                    alert_message: alertMessage
                }
            });
        },

        async loadBatchOrders() {
            this.$root.$dialogLoader.show('Loading orders...', {});
            const orderIds = this.currentBatchOrders.map(
                order => order.order.id
            );
            const ordersPromise = orderIds.map(id => {
                return data.orders.getOrder(id);
            });
            const ordersRef = await Promise.all(ordersPromise);
            const orders = ordersRef.map(async order => {
                order = order.data();
                await this.loadVerificationForOrder(order);
                return order;
            });

            this.batchOrders = await Promise.all(orders);
            this.$root.$dialogLoader.hide();
        },

        async loadVerificationForOrder(order) {
            if (order.verification && 'cartId' in order.verification) {
                return false;
            }
            order.verification = await data.orders.getVerificationForOrder(
                order,
                this.currentUser
            );
        },

        async claimBatchOrders() {
            this.$root.$dialogLoader.show('Claiming orders...', {});
            const promises = this.batchOrders.map(order => {
                order.internalStatus = 'in-progress';
                data.orderHistory.save({
                    orderId: order.order.id,
                    action: 'pulling-start',
                    description: `Order is assigned to ${this.currentUser.displayName} and started pulling as part of a Batch`
                });
                return this.$store.dispatch('updateOrder', {
                    orderId: order.order.id,
                    data: {
                        internalStatus: 'in-progress',
                        assigned: this.currentUser.uid
                    }
                });
            });
            await Promise.all(promises);
            this.$root.$dialogLoader.hide();
        },

        scanOrdersCartId() {
            const self = this;
            let doAllOrdersHaveCartId = true;
            self.batchOrders.every(order => {
                if (order.verification.cartId === '') {
                    doAllOrdersHaveCartId = false;
                    self.currentOrder = order;
                    return false;
                }
                return true;
            });

            if (doAllOrdersHaveCartId === true) {
                // now that all cart-ids have been scanned/assigned, we can start pulling items
                self.startBatchOrdersItemsScanning();
            }
        },

        startBatchOrdersItemsScanning() {
            this.groupOrderItems();
            this.moveToNextItem();
        },

        groupOrderItems() {
            let batchOrdersItems = [];
            this.batchOrders.map(order => {
                let orderItems = order.verification.items;
                orderItems.map(item => {
                    item.orderId = order.order.id;
                });
                batchOrdersItems = batchOrdersItems.concat(orderItems);
            });
            batchOrdersItems.sort(
                (a, b) =>
                    a.locationNumber - b.locationNumber ||
                    a.orderItem.sku.localeCompare(b.orderItem.sku)
            );
            this.batchOrdersItems = batchOrdersItems;
        },

        moveToNextItem() {
            const self = this;
            self.error = '';
            let nextItem = {};
            self.batchOrdersItems.every((item, index) => {
                if (self.restockItemIds.includes(item.orderItem.id)) {
                    return true;
                }
                if (item.status == 'incomplete') {
                    nextItem = item;
                    self.currentIncompleteItemIndex = index;
                    return false;
                }
                return true;
            });
            if (nextItem && nextItem.status) {
                self.currentIncompleteItem = nextItem;
                self.currentOrder = self.getOrderById(nextItem.orderId);
            } else {
                // no more items to scan/complete
                self.$root.$dialogLoader.show(
                    'Completing Orders Pulling...',
                    {}
                );
                const completeOrders = self.batchOrders.filter(
                    order => order.internalStatus !== 'needs-restock'
                );
                const promises = completeOrders.map(order => {
                    return data.orderHistory.save({
                        orderId: order.order.id,
                        action: 'pulling-end',
                        description:
                            'Order is finished picking by ' +
                            self.currentUser.displayName
                    });
                });
                Promise.all(promises)
                    .then(() => {
                        self.$root.$dialogLoader.hide();
                        self.completePicking();
                    })
                    .catch(err => {
                        console.error('Error marking orders as completed', err);
                        self.$root.$dialogLoader.hide();
                    });
            }
        },

        getOrderById(orderId) {
            let result = {};
            this.batchOrders.every(order => {
                if (order.order.id === orderId) {
                    result = order;
                    return false;
                }
                return true;
            });
            return result;
        },

        async scan(response) {
            this.error = '';
            if (this.currentOrder.verification.cartId == '') {
                this.$root.$dialogLoader.show('scanning for cart-id', {});
                data.verifications
                    .getByCart(response.input)
                    .then(async querySnapshot => {
                        if (querySnapshot.size) {
                            this.error =
                                'This cart-id has been taken by another order.';
                        } else {
                            const patternToMatchNumberOnly = /^\d+$/;
                            if (
                                patternToMatchNumberOnly.test(
                                    response.input
                                ) === false
                            ) {
                                this.error =
                                    'Only numeric value is allowed for the cart-id.';
                                return;
                            }
                            this.$store
                                .dispatch('updateVerification', {
                                    orderId: this.orderId,
                                    data: { cartId: response.input }
                                })
                                .then(async () => {
                                    this.currentOrder.verification.cartId =
                                        response.input;
                                    await this.showNewCustomerOrderFlag(
                                        this.currentOrder
                                    );
                                    this.scanOrdersCartId();
                                });
                        }
                        this.$root.$dialogLoader.hide();
                    });
            } else {
                this.error = '';
                if (
                    this.scannedItems.includes(
                        this.currentIncompleteItem.orderItem.id
                    )
                ) {
                    // item has been scanned. now scan its cart-id
                    if (
                        response.input != this.currentOrder.verification.cartId
                    ) {
                        this.error = 'You are scanning the incorrect cart-id.';
                        return false;
                    } else {
                        this.moveToNextItem();
                    }
                } else {
                    await this.scanItemBarcode(response.input);
                }
            }
        },

        async scanItemBarcode(scannedInput) {
            if (scannedInput != this.currentIncompleteItem.barcode) {
                this.error = 'You are scanning the incorrect item.';
                return false;
            }
            let message = '';
            if (this.currentIncompleteItem.orderItem.quantity > 1) {
                message =
                    'There should be ' +
                    this.currentIncompleteItem.orderItem.quantity +
                    ' of this item';
            }
            if (this.currentIncompleteItem.orderItem.sku.includes('-case')) {
                message =
                    'This item is a case (x' +
                    this.currentIncompleteItem.orderItem.quantity +
                    ')';
            }

            if (message === '') {
                this.completeOrderItem();
                return;
            }
            if (await this.$root.$confirmDialog.open(null, message, {})) {
                this.completeOrderItem();
            }
        },

        completeOrderItem() {
            this.scannedItems.push(this.currentIncompleteItem.orderItem.id);
            this.currentIncompleteItem['status'] = 'complete';
            this.$store.dispatch('updateVerificationItem', {
                orderId: this.orderId,
                itemId: this.currentIncompleteItem.orderItem.id,
                data: {
                    status: 'complete',
                    confirm: {
                        method: 'scan'
                    }
                }
            });
        },

        async setOutOfStock() {
            if (
                await this.$root.$confirmDialog.open(
                    null,
                    'This item is out of stock?',
                    {}
                )
            ) {
                this.currentIncompleteItem['status'] = 'out-of-stock';
                this.$store.dispatch('updateVerificationItem', {
                    orderId: this.orderId,
                    itemId: this.currentIncompleteItem.orderItem.id,
                    data: {
                        status: 'out-of-stock'
                    }
                });

                data.orderHistory.save({
                    orderId: this.orderId,
                    action: 'out-of-stock',
                    description:
                        'Item ' +
                        this.currentIncompleteItem.orderItem.sku +
                        ' is marked as Out-of-Stock by ' +
                        this.currentUser.displayName
                });
                this.scannedItems.push(this.currentIncompleteItem.orderItem.id);
            }
        },

        async markItemAsSubstitute(response) {
            this.currentIncompleteItem['status'] = 'complete';
            this.$store.dispatch('updateVerificationItem', {
                orderId: this.orderId,
                itemId: this.currentIncompleteItem.orderItem.id,
                data: {
                    status: 'complete',
                    confirm: {
                        method: 'replacement',
                        additional: response.sku + ': ' + response.upc
                    }
                }
            });
            this.scannedItems.push(this.currentIncompleteItem.orderItem.id);
        },

        async setRestockNeeded() {
            if (
                await this.$root.$confirmDialog.open(
                    null,
                    'The item needs to be restocked?',
                    {}
                )
            ) {
                this.restockItemIds.push(
                    this.currentIncompleteItem.orderItem.id
                );
                this.$store.dispatch('createRestockItem', {
                    sku: this.currentIncompleteItem.orderItem.sku,
                    qtyNeeded: this.currentIncompleteItem.orderItem.quantity,
                    cartId: this.currentOrder.verification.cartId,
                    requested: Date.now()
                });
                this.currentOrder.internalStatus = 'needs-restock';
                this.$store.dispatch('updateOrder', {
                    orderId: this.orderId,
                    data: {
                        internalStatus: 'needs-restock'
                    }
                });
                data.orderHistory.save({
                    orderId: this.orderId,
                    action: 'restock-needed',
                    description:
                        'Item ' +
                        this.currentIncompleteItem.orderItem.sku +
                        ' is marked as Awaiting Restock by ' +
                        this.currentUser.displayName
                });
                this.hasRestockItem = true;
                this.scannedItems.push(this.currentIncompleteItem.orderItem.id);
            }
        },

        async manualConfirmOrderItem() {
            let message = 'This will manually confirm the item';
            if (this.currentIncompleteItem.orderItem.sku.includes('-case')) {
                message =
                    message +
                    ' (' +
                    this.currentIncompleteItem.orderItem.quantity +
                    ')';
            }
            if (await this.$root.$confirmDialog.open(null, message, {})) {
                const payload = {
                    status: 'complete',
                    confirm: {
                        method: 'manual'
                    }
                };
                this.currentIncompleteItem['status'] = 'complete';
                this.$store.dispatch('updateVerificationItem', {
                    orderId: this.orderId,
                    itemId: this.currentIncompleteItem.orderItem.id,
                    data: payload
                });
                this.scannedItems.push(this.currentIncompleteItem.orderItem.id);
            }
        },

        async completeWithoutScan() {
            if (
                await this.$root.$confirmDialog.open(
                    null,
                    'This will manually confirm the item',
                    {}
                )
            ) {
                this.currentIncompleteItem['status'] = 'complete';
                this.$store.dispatch('updateVerificationItem', {
                    orderId: this.orderId,
                    itemId: this.currentIncompleteItem.orderItem.id,
                    data: {
                        status: 'complete',
                        confirm: {
                            method: 'manual (External Order)'
                        }
                    }
                });
                this.scannedItems.push(this.currentIncompleteItem.orderItem.id);
            }
        },

        async showNewCustomerOrderFlag(order) {
            const customerEmail = order.order?.customer_email;
            if (!customerEmail) {
                return;
            }
            this.$root.$dialogLoader.show(
                "Checking if it's customer first order…",
                {}
            );
            if (await this.doesOrderBelongToNewCustomer(customerEmail)) {
                this.$root.$confirmDialog.open(
                    null,
                    `This (order #${order.order.id}) is the first order of the customer.`,
                    {}
                );
            }
            this.$root.$dialogLoader.hide();
        },

        async doesOrderBelongToNewCustomer(customerEmail) {
            const orders = await data.orders.getCustomerOrders(customerEmail);
            return !!(
                orders &&
                orders.length == 1 &&
                orders[0].billing_address.email == customerEmail
            );
        },

        completePicking() {
            this.$store
                .dispatch('assignCurrentBatchOrder', this.batchOrders)
                .then(() => {
                    this.$router.push({ name: 'MultiOrderComplete' });
                })
                .catch(err => {
                    this.error(err);
                });
        }
    }
};
</script>
