<template>
    <v-card width="100%" flat class="mx-auto">
        <v-card-title>
            <span>Active Cart Statuses</span>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-alert
            v-model="show_alert"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            elevation="2"
            dismissible
        >
            {{ alert_message }}
        </v-alert>
        <v-data-table
            :sort-by="['age']"
            :headers="headers"
            :items="rows"
            class="elevation-2 mx-auto complete-table"
        >
        </v-data-table>
    </v-card>
</template>

<script>
//import { db } from '@/firebase';
import * as data from '@/data/index';

export default {
    data() {
        return {
            show_alert: false,
            alert_message: '',
            headers: [
                {
                    text: 'Cart ID',
                    align: 'start',
                    sortable: false,
                    value: 'cartId',
                    width: '125px'
                },
                { text: 'Order ID', value: 'orderId' },
                { text: 'Assigned To', value: 'assignedTo' },
                { text: 'Status', value: 'status' },
                { text: 'Ship Later', value: 'shipLater' }
            ],
            orderItems: [],
            selected: []
        };
    },
    mounted() {
        this.loadCartStatusItems();
    },
    computed: {
        rows() {
            return this.orderItems.map(r => {
                return {
                    cartId: r.verification.cartId,
                    orderId: r.order.id,
                    assignedTo: r.verification.user
                        ? r.verification.user.displayName
                        : '---',
                    status: this.uppercaseWords(
                        r.internalStatus.replace(/-/g, ' ')
                    ),
                    shipLater: this.getShipLaterDate(r)
                };
            });
        }
    },
    methods: {
        uppercaseWords(string) {
            return string.replace(/\w\S*/g, function(txt) {
                return (
                    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
            });
        },
        getShipLaterDate(item) {
            return data.orders.getOrderShipLaterDate(item) || '---';
        },
        loadCartStatusItems() {
            this.$root.$dialogLoader.show('Loading report...', {});
            // Actually need to get the cart status == "in-pg"
            data.orders
                .getByNotStatus(['complete', 'queued'])
                .then(async snapshot => {
                    const promises = snapshot.docs.map(async snap => {
                        let data = snap.data();
                        data.uid = snap.id;
                        data.verification = await snap
                            .get('verification')
                            .get();
                        //data.name = await this.getProductName(data.sku);
                        return data;
                    });
                    this.orderItems = await Promise.all(promises);
                    this.orderItems.map(async item => {
                        item.verification = item.verification.data();
                        return item;
                    });
                    this.selected = [];
                    this.$root.$dialogLoader.hide();
                });
        },
        async getProductName(sku) {
            if (sku == '') {
                return '';
            }
            const product = await data.products.getProductBySku(sku);
            return product && product.data && product.data.length
                ? product.data[0].name
                : '';
        }
    }
};
</script>
