<template>
    <div style="width: 100%">
        <v-alert
            v-show="error != ''"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            elevation="2"
        >
            {{ error }}
        </v-alert>
        <v-btn
            block
            color="secondary"
            elevation="0"
            class="mb-4"
            :disabled="getUnAssignedOrdersCount <= 0"
            @click="getNextAvailableOrder"
            >Next Available Order</v-btn
        >
        <v-card width="100%" class="mx-auto">
            <v-card>
                <v-card-title>Search by Order Number</v-card-title>
                <v-form>
                    <v-card-text>
                        <v-text-field
                            label="Order Number"
                            v-on:keypress="isNumber($event)"
                            v-model.trim="orderNumber"
                            :rules="[rules.required]"
                            ref="order_number"
                        />
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-btn
                            color="primary"
                            type="submit"
                            @click.prevent="searchOrder"
                            >Search</v-btn
                        >
                    </v-card-actions>
                </v-form>
            </v-card>
            <order-preview
                :order="nextAvailableOrder"
                v-model="showNextAvailableOrderInfo"
                @addOrderToBatch="addOrderToBatch"
            ></order-preview>
        </v-card>
        <v-data-table
            v-show="batchOrders.length > 0"
            disable-sort
            hide-default-header
            :items="batchOrders"
            class="elevation-2 mx-auto complete-table"
        >
            <template #body="{ items }">
                <thead>
                    <tr>
                        <th>#ID</th>
                        <th>Internal Status</th>
                        <th>Delayed Shipping Until</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in items" :key="item.order.id">
                        <td>
                            {{ item.order.id }}
                        </td>
                        <td>{{ item.internalStatus }}</td>
                        <td v-if="item.delay && item.delay.date">
                            {{ item.delay.date }}
                        </td>
                        <td v-else></td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
        <v-btn
            block
            color="secondary"
            elevation="0"
            class="mb-4"
            v-show="batchOrders.length > 0"
            @click="startBatchPulling"
            >Start Batch Pulling</v-btn
        >
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import * as data from '@/data/index';
import OrderPreview from '@/components/MultiOrderPickup/OrderPreview';

export default {
    components: { OrderPreview },
    name: 'MultiOrderPickup-Home',
    data() {
        return {
            orderNumber: '',
            rules: {
                required: value => !!value || 'The field is required.'
            },
            error: '',
            availableOrdersOffset: 0,
            nextAvailableOrder: {},
            showNextAvailableOrderInfo: false,
            batchOrders: [],
            batchOrderIds: []
        };
    },
    computed: {
        ...mapState(['unassignedOrders', 'users', 'currentUser']),
        ...mapGetters(['getUnAssignedOrdersCount'])
    },
    methods: {
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if ((charCode >= 48 && charCode <= 57) || charCode === 13) {
                // 13 is for enter key
                return true;
            }
            evt.preventDefault();
        },

        getNextAvailableOrder() {
            const self = this;
            self.error = '';
            self.nextAvailableOrder = {};

            if (!(self.availableOrdersOffset in self.unassignedOrders)) {
                self.error = 'There is no available order at the moment.';
                return false;
            }
            self.nextAvailableOrder =
                self.unassignedOrders[self.availableOrdersOffset];
            self.availableOrdersOffset++;

            if (
                self.doesOrderAlreadyExistInBatch(
                    self.nextAvailableOrder.order.id
                )
            ) {
                // order has already ben added to the batch. Trying another.
                return self.getNextAvailableOrder();
            }
            if (self.isOrderAssignedToSomeoneElse(self.nextAvailableOrder)) {
                // order is assigned to someone else. Trying another.
                return self.getNextAvailableOrder();
            }
            if (self.doesOrderHaveGiftItem(self.nextAvailableOrder)) {
                // The next available order has a gift item in it. Trying another.;
                return self.getNextAvailableOrder();
            }
            if (!self.canOrderBeAddedToBatch(self.nextAvailableOrder)) {
                // order can't be pulled. so trying another.
                return self.getNextAvailableOrder();
            }
            if (self.nextAvailableOrder.has_bundle_product === true) {
                // order has bundle item(s) in it. so trying another.;
                return self.getNextAvailableOrder();
            }

            self.showNextAvailableOrderInfo = true;
        },

        async searchOrder() {
            let self = this;
            self.error = '';
            const order = await this.getSearchedOrder();

            if (!order || !order.order) {
                self.error = `The searched order(${self.orderNumber}) not found!`;
                self.$root.$dialogLoader.hide();
                return false;
            }

            if (this.doesOrderAlreadyExistInBatch(order.order.id)) {
                self.error = `The searched order(${self.orderNumber}) has already ben added to the batch. Try another order.`;
                self.$root.$dialogLoader.hide();
                return false;
            }
            if (self.isOrderAssignedToSomeoneElse(order)) {
                self.error = `The searched order(${self.orderNumber}) is assigned to someone else. Try another order.`;
                self.$root.$dialogLoader.hide();
                return false;
            }
            if (self.doesOrderHaveGiftItem(order)) {
                self.error = `The searched order(${self.orderNumber}) has gift items in it. Try another order.`;
                self.$root.$dialogLoader.hide();
                return false;
            }
            if (!self.canOrderBeAddedToBatch(order)) {
                self.error = `The searched order(${self.orderNumber}) is already in the batch. Try another order.`;
                self.$root.$dialogLoader.hide();
                return false;
            }
            if (order.has_bundle_product === true) {
                self.error = `The searched order(${self.orderNumber}) has bundle item(s) in it. Try another order.`;
                self.$root.$dialogLoader.hide();
                return false;
            }
            self.addOrderToBatch(order);
            self.orderNumber = '';
            self.$refs['order_number'].focus();
            self.$root.$dialogLoader.hide();
        },

        async getSearchedOrder() {
            let self = this;
            self.error = '';
            self.$root.$dialogLoader.show('Searching for order...', {});
            return await data.orders
                .getOrder(this.orderNumber)
                .then(querySnapshot => {
                    const order = querySnapshot.data();
                    if (order && order.order && order.order.id) {
                        return order;
                    } else {
                        this.error = 'Order not found!';
                        self.$root.$dialogLoader.hide();
                        return {};
                    }
                })
                .catch(function(error) {
                    console.error(error);
                    self.error = 'There was an error getting the order';
                    self.$root.$dialogLoader.hide();
                });
        },

        doesOrderAlreadyExistInBatch(orderId) {
            return this.batchOrderIds.includes(orderId);
        },

        isOrderAssignedToSomeoneElse(order) {
            const self = this;
            if (
                order.assigned != null &&
                order.assigned != self.currentUser.uid
            ) {
                return true;
            }
            return false;
        },

        doesOrderHaveGiftItem(order) {
            const giftItemsSkus = [
                'GIFT BOX',
                'GIFT BOX-POB',
                'gift collection'
            ];
            return giftItemsSkus.some(v => order.items.includes(v)) === true;
        },

        canOrderBeAddedToBatch(order) {
            const orderId = order.order.id;
            switch (order.internalStatus) {
                case 'merged':
                    this.error = `Order (${orderId}) has been merged to order #${order.merged_into_order}.`;
                    break;
                case 'needs-restock':
                    this.error = `Order (${orderId}) has out-of-stock items and needs to be restocked.`;
                    break;
                case 'verified':
                    this.error = `Order (${orderId}) has been pulled and has ben Verified.`;
                    break;
                case 'needs-shipped':
                    this.error = `Order (${orderId}) has been pulled and needs to be shipped.`;
                    break;
                case 'complete':
                    this.error = `Order (${orderId}) has already been completed.`;
                    break;
                default:
                    return true;
            }
            return false;
        },

        addOrderToBatch(order) {
            data.orders.lockOrder(order).then(() => {
                this.batchOrderIds.push(order.order.id);
                this.batchOrders.push(order);
            });
        },

        async loadVerificationForOrder(order) {
            if (order.verification && 'cartId' in order.verification) {
                return false;
            }
            order.verification = await data.orders.getVerificationForOrder(
                order,
                this.currentUser
            );
        },

        async startBatchPulling() {
            const self = this;
            self.$root.$dialogLoader.show('Preparing orders...', {});
            const promises = self.batchOrders.map(order => {
                return self.loadVerificationForOrder(order);
            });
            await Promise.all(promises);
            self.$store
                .dispatch('assignCurrentBatchOrder', self.batchOrders)
                .then(() => {
                    self.$root.$dialogLoader.hide();
                    self.$router.push({
                        name: 'MultiOrderPulling'
                    });
                })
                .catch(err => {
                    self.error = err;
                    self.$root.$dialogLoader.hide();
                });
        }
    }
};
</script>
