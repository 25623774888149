<template>
    <v-dialog persistent v-model="show" max-width="600px">
        <v-card>
            <v-card-text class="text-center" v-if="order && order.order">
                <h3 class="subtitle">Order Number</h3>
                <h2 class="title">{{ order.order.id }}</h2>
            </v-card-text>
            <v-card
                elevation="0"
                class="mb-4"
                v-if="order && order.picking_units"
            >
                <v-card-text class="text-center">
                    <h2 class="subtitle">Units to Pick</h2>
                    <h1 class="title">
                        Single {{ order.picking_units.single }}
                    </h1>
                    <h1 class="title">Cases {{ order.picking_units.case }}</h1>
                </v-card-text>
            </v-card>
            <v-card
                elevation="0"
                class="mb-4"
                v-else-if="order && order.itemCount"
            >
                <v-card-text class="text-center">
                    <h2 class="subtitle">Units to Pick</h2>
                    <h1 class="title">
                        {{ order.itemCount }}
                    </h1>
                </v-card-text>
            </v-card>
            <v-card elevation="0" class="mb-4">
                <v-card-text class="text-center">
                    <v-row class="text-left">
                        <v-simple-table
                            class="fullwidth"
                            dense
                            s
                            v-if="order && order.order"
                        >
                            <template v-slot:default>
                                <tbody>
                                    <tr
                                        v-if="
                                            order.is_perishable &&
                                                order.is_perishable === true
                                        "
                                    >
                                        <td>Perishable</td>
                                        <td>Yes</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>
                                            <v-chip color="primary">
                                                {{ order.internalStatus }}
                                            </v-chip>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Assigned to</td>
                                        <td>{{ orderAssignedTo }}</td>
                                    </tr>
                                    <tr v-if="order.delay && order.delay.date">
                                        <td>Delayed Shipping until</td>
                                        <td>
                                            {{ order.delay.date }}
                                            by
                                            <v-chip small>{{
                                                getOrderDelayedByUser
                                            }}</v-chip>
                                        </td>
                                    </tr>
                                    <tr
                                        v-if="getShipLaterDate != ''"
                                        class="highlight"
                                    >
                                        <td>Ship Later Date</td>
                                        <td>
                                            <h3>{{ getShipLaterDate }}</h3>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text color="primary" @click="show = false">
                    Skip Order
                </v-btn>

                <v-btn
                    color="secondary"
                    @click="(show = false), addOrderToBatch()"
                >
                    Add Order to Batch
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import * as data from '@/data/index';
export default {
    props: {
        value: Boolean,
        order: {
            type: Object
        }
    },
    computed: {
        ...mapState(['users']),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        orderAssignedTo() {
            let assignedTo = '';
            this.users.every(user => {
                if (user.id == this.order.assigned) {
                    assignedTo = user.displayName;
                    return false;
                }
                return true;
            });
            return assignedTo;
        },
        getOrderDelayedByUser() {
            let delayedBy = '';
            if (this.order.delay && this.order.delay.date) {
                this.users.every(user => {
                    if (user.id == this.order.delay.user) {
                        delayedBy = user.displayName;
                        return false;
                    }
                    return true;
                });
            }
            return delayedBy;
        },
        getShipLaterDate() {
            return data.orders.getOrderShipLaterDate(this.order);
        }
    },
    methods: {
        addOrderToBatch() {
            this.$emit('addOrderToBatch', this.order);
        }
    }
};
</script>

<style></style>
