<template>
    <v-dialog v-model="show">
        <v-card>
            <v-card-title
                class="headline"
                v-if="currentOrder && currentOrder.order"
            >
                Order #{{ currentOrder.order.id }}
            </v-card-title>

            <v-card-text>
                Assign order to
                <v-select
                    v-model="selectedUser"
                    :items="users"
                    label="Please select a user"
                    item-value="id"
                    item-text="email"
                    outlined
                ></v-select>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text color="primary" @click="show = false">
                    Cancel
                </v-btn>

                <v-btn
                    color="secondary"
                    :disabled="selectedUser == ''"
                    @click="(show = false), assignUser()"
                >
                    Confirm
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import * as data from '@/data/index';

export default {
    props: {
        value: Boolean
    },
    data: () => {
        return {
            selectedUser: ''
        };
    },
    computed: {
        ...mapState(['users', 'currentOrder', 'currentUser']),
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    mounted() {},
    methods: {
        assignUser() {
            this.$store.dispatch('updateOrder', {
                orderId: this.currentOrder.order.id,
                data: { assigned: this.selectedUser }
            });
            data.orderHistory.save({
                orderId: this.currentOrder.order.id,
                action: 'assigned',
                description:
                    'Assigned order to ' + this.getSelectedUserDisplayName()
            });
            if (this.selectedUser != this.currentUser.uid) {
                this.$emit('showNextOrder');
            }
            this.selectedUser = '';
        },

        getSelectedUserDisplayName() {
            let self = this;
            let displayName = '';

            self.users.every(user => {
                if (user.id == self.selectedUser) {
                    displayName = user.displayName;
                    return false;
                }
                return true;
            });
            return displayName;
        }
    }
};
</script>
