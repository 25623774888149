<template
    ><div style="width: 100%">
        <v-data-table
            v-show="currentBatchOrders.length > 0"
            disable-sort
            hide-default-header
            :items="currentBatchOrders"
            class="elevation-2 mx-auto complete-table"
        >
            <template #body="{ items }">
                <thead>
                    <tr>
                        <th>#ID</th>
                        <th>CART ID</th>
                        <th>Internal Status</th>
                        <th>Perishable</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="order in items" :key="order.order.id">
                        <td>
                            {{ order.order.id }}
                        </td>
                        <td>{{ order.verification.cartId }}</td>
                        <td>
                            {{ translateStatus(order.internalStatus) }}
                        </td>
                        <td>
                            {{ order.is_perishable === true ? 'Yes' : 'No' }}
                        </td>
                    </tr>
                </tbody>
            </template> </v-data-table
        ><v-btn
            block
            color="secondary"
            elevation="0"
            class="my-4"
            @click="goToMainMenu"
            >Main Menu</v-btn
        ><v-btn
            block
            color="secondary"
            elevation="0"
            class="my-4"
            @click="startNewBatchPulling"
            >Pick Batch Orders</v-btn
        >
    </div></template
>

<script>
import { mapState } from 'vuex';
import * as data from '@/data/index';

export default {
    computed: {
        ...mapState(['currentBatchOrders'])
    },
    created() {
        this.$root.$dialogLoader.show('Unlocking Batch orders...', {});
        const promises = this.currentBatchOrders.map(order => {
            return data.orders.unlockOrder(order);
        });
        Promise.all(promises).then(() => {
            this.$root.$dialogLoader.hide();
        });
    },
    methods: {
        translateStatus(status) {
            switch (status) {
                case 'in-progress':
                    return 'Complete Picking';
                case 'needs-restock':
                    return 'Needs Ordered';
                default:
                    return status;
            }
        },

        finishMultiOrderPicking() {
            const promises = this.currentBatchOrders.map(order => {
                return this.$store.dispatch('updateOrder', {
                    orderId: order.order.id,
                    data: {
                        assigned: null
                    }
                });
            });
            return Promise.all(promises);
        },

        goToMainMenu() {
            this.$root.$dialogLoader.show('Releasing orders...', {});
            this.finishMultiOrderPicking().then(() => {
                this.$store.dispatch('assignCurrentBatchOrder', []).then(() => {
                    this.$root.$dialogLoader.hide();
                    this.$router.push({ name: 'Home' });
                });
            });
        },

        startNewBatchPulling() {
            this.$root.$dialogLoader.show('Releasing orders...', {});
            this.finishMultiOrderPicking().then(() => {
                this.$store.dispatch('assignCurrentBatchOrder', []).then(() => {
                    this.$root.$dialogLoader.hide();
                    this.$router.push({ name: 'MultiOrderPickingSelection' });
                });
            });
        }
    }
};
</script>
