<template>
    <div style="width: 100%">
        <v-alert
            v-show="order_weight > 50"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            elevation="2"
            dismissible
        >
            The order weight is over 50 pounds.
        </v-alert>
        <v-alert
            v-model="show_alert"
            color="#4c9aff"
            border="left"
            colored-border
            type="error"
            elevation="2"
            dismissible
        >
            {{ alert_message }}
        </v-alert>
        <div class="container page">
            <div class="row">
                <div class="col-md-6 offset-md-3 col-xs-12">
                    <h1 class="text-center">#{{ orderId }}</h1>
                    <div
                        v-for="shippingAddress in shippingAddresses"
                        :key="shippingAddress.id"
                    >
                        <p class="text-center">
                            {{ shippingAddress.street_1 }},
                            {{ shippingAddress.city }}
                        </p>
                        <p class="text-center">
                            {{ shippingAddress.state }},
                            {{ shippingAddress.zip }}
                        </p>
                    </div>
                    <h3 class="text-center" v-if="getShipLaterDate != ''">
                        Ship Later Date: {{ getShipLaterDate }}
                    </h3>
                </div>
            </div>
            <div class="row">
                <v-data-table
                    :headers="headers"
                    disable-sort
                    hide-default-header
                    :items="orderItems"
                    :items-per-page="10"
                    class="elevation-2 mx-auto complete-table"
                >
                    <template #body="{ items }">
                        <thead>
                            <tr>
                                <th>Qty</th>
                                <th>Name</th>
                                <th>Confirmed</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in items"
                                :key="item.orderItem.id"
                                :class="{
                                    primary: scannedItem.includes(
                                        item.orderItem.sku
                                    )
                                }"
                            >
                                <td
                                    :class="{
                                        'scan-little':
                                            scannedQtyBySku[
                                                item.orderItem.sku
                                            ] <
                                            itemsQtyBySku[item.orderItem.sku],
                                        'scan-exact':
                                            scannedQtyBySku[
                                                item.orderItem.sku
                                            ] ==
                                            itemsQtyBySku[item.orderItem.sku],
                                        'scan-many':
                                            scannedQtyBySku[
                                                item.orderItem.sku
                                            ] >
                                            itemsQtyBySku[item.orderItem.sku]
                                    }"
                                >
                                    {{ item.orderItem.quantity }}
                                    <span
                                        v-if="
                                            item.orderItem.sku.includes('-case')
                                        "
                                    >
                                        Case</span
                                    >
                                </td>
                                <td>
                                    {{ item.orderItem.name }}
                                    <v-badge
                                        v-show="item.status == 'out-of-stock'"
                                        content="out-of-stock"
                                        class="ml-2"
                                    ></v-badge>
                                </td>
                                <td v-text="item.confirm.method"></td>
                                <td>
                                    {{ item.status }}
                                    <span
                                        v-show="
                                            item.confirm.method == 'replacement'
                                        "
                                    >
                                        Substituted SKU:
                                        {{ item.confirm.additional }} | It
                                        replaced ({{ item.orderItem.sku }})
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
            <div class="row mt-7">
                <v-card-text>
                    <v-row>
                        <v-col
                            ><div class="my-2">
                                <v-btn
                                    block
                                    color="#4c9aff"
                                    dark
                                    elevation="0"
                                    :class="{ 'btn-disabled': isVerified }"
                                    @click="verifyOrder"
                                >
                                    <v-icon small class="mr-2">fa-check</v-icon>
                                    {{
                                        isVerified ? 'Verified' : 'Verify order'
                                    }}
                                </v-btn>
                            </div>
                            <div class="my-2">
                                <v-btn
                                    block
                                    color="#4c9aff"
                                    dark
                                    elevation="0"
                                    :class="{ 'btn-disabled': isPicked }"
                                    @click="printAndPack"
                                >
                                    <v-icon small class="mr-2"
                                        >fa-box-open</v-icon
                                    >
                                    {{ isPicked ? 'Picked' : 'Print and Pack' }}
                                </v-btn>
                            </div></v-col
                        >
                        <v-col
                            ><div class="my-2">
                                <v-btn
                                    block
                                    color="#4c9aff"
                                    dark
                                    elevation="0"
                                    :to="'/gift-message/' + orderId"
                                    target="_blank"
                                    v-show="hasGiftMessage"
                                >
                                    <v-icon small class="mr-2">fa-gift</v-icon>
                                    Print Gift Message
                                </v-btn>
                            </div>
                            <div class="my-2">
                                <v-btn
                                    block
                                    color="#4c9aff"
                                    dark
                                    elevation="0"
                                    @click="nextOrder"
                                >
                                    Next Order
                                </v-btn>
                            </div>
                            <div class="my-2">
                                <v-btn
                                    block
                                    color="#4c9aff"
                                    dark
                                    elevation="0"
                                    @click="showDatePicker = true"
                                >
                                    <span class="badge-icon">
                                        <v-icon small class="mr-2"
                                            >fa-truck</v-icon
                                        >
                                        <v-icon
                                            class="badge"
                                            color="secondary darken-3"
                                            x-small
                                            >fa-clock</v-icon
                                        >
                                    </span>
                                    Ship Later
                                </v-btn>
                            </div></v-col
                        >
                    </v-row>
                    <DatePicker
                        v-model="showDatePicker"
                        @selectedDate="setShipDate"
                    ></DatePicker>
                    <ScannableInput
                        :active="true"
                        :debug="false"
                        @scan="scan"
                    ></ScannableInput>
                </v-card-text>
            </div>
        </div>
    </div>
</template>

<style scoped>
.v-application .primary {
    background-color: #def9d7 !important;
    border-color: #def9d7 !important;
}
.v-application .primary .scan-little {
    color: #252525 !important;
    font-weight: 700;
}
.v-application .primary .scan-exact {
    color: black !important;
    font-weight: 700;
}
.v-application .primary .scan-many {
    color: red !important;
    font-weight: 700;
}
.btn-disabled {
    opacity: 0.7;
    pointer-events: none;
}
</style>

<script>
import { mapState } from 'vuex';
import * as data from '@/data/index';
import DatePicker from '@/components/DatePicker';
import ScannableInput from '@/components/ScannableInput';

export default {
    name: 'Complete',
    data() {
        return {
            shippingAddresses: [],
            headers: [
                { text: 'QTY', value: 'orderItem.quantity' },
                {
                    text: 'Name',
                    align: 'start',
                    sortable: false,
                    value: 'orderItem.name'
                },

                { text: 'Method', value: 'confirm.method' },
                { text: 'Status', value: 'status' }
            ],
            showDatePicker: false,
            scannedItem: [],
            show_alert: false,
            alert_message: '',
            scannedQtyBySku: {},
            itemsQtyBySku: {},
            order_weight: 0
        };
    },
    components: {
        DatePicker,
        ScannableInput
    },
    computed: {
        ...mapState([
            'currentOrder',
            'currentUser',
            'currentOrderQueue',
            'canDisplayOrderCustomerServiceNote'
        ]),
        orderId() {
            return this.$route.params.id;
        },
        orderItems() {
            let orderItems = [];
            if (this.currentOrder && this.currentOrder.verification) {
                const nonGiftBundleItemsIds = this.getNonGiftBundleItemsIds(
                    this.currentOrder.verification.items
                );
                this.currentOrder.verification.items.every(item => {
                    if (item.orderItem.is_bundled_product === true) {
                        orderItems.push(item);
                        return true;
                    }
                    if (
                        item.orderItem.is_bundled_product === false &&
                        !nonGiftBundleItemsIds.includes(item.orderItem.id)
                    ) {
                        orderItems.push(item);
                    }
                    return true;
                });
            }
            return orderItems;
        },
        itemsSkuByUpc() {
            let itemsSkuByUpc = {};
            if (this.currentOrder && this.currentOrder.verification) {
                this.orderItems.forEach(item => {
                    itemsSkuByUpc[item.barcode] = item.orderItem.sku;
                });
            }
            return itemsSkuByUpc;
        },
        hasGiftMessage() {
            if (!this.currentOrder) {
                return false;
            }
            return data.orderGiftMessage.hasGiftMessage(this.currentOrder);
        },
        isVerified() {
            return (
                this.currentOrder &&
                this.currentOrder.internalStatus == 'verified'
            );
        },
        isPicked() {
            return (
                this.currentOrder &&
                this.currentOrder.internalStatus == 'needs-shipped'
            );
        },
        getShipLaterDate() {
            return data.orders.getOrderShipLaterDate(this.currentOrder);
        }
    },
    mounted() {
        data.orders
            .getOrderShippingAddresses(this.currentOrder.order.id)
            .then(response => response.json())
            .then(responseJSON => {
                this.shippingAddresses = responseJSON;
            });
        this.order_weight = data.orders.getOrderItemsWeight(this.orderItems);
    },
    created() {
        this.$root.$dialogLoader.show('Creating Verification Check…', {});

        if (this.$route.params.id != this.currentOrder.order.id) {
            this.redirectToPage('Home', {
                show_alert: true,
                alert_message: 'Invalid Order ID'
            });
        } else if (this.currentOrder.internalStatus == 'merged') {
            this.redirectToPage('Home', {
                show_alert: true,
                alert_message:
                    'This order has been merged to order ' +
                    this.currentOrder.merged_into_order
            });
        } else if (this.currentOrder.internalStatus == 'complete') {
            this.redirectToPage('Home', {
                show_alert: true,
                alert_message: 'This order is already completed.'
            });
        } else if (this.currentOrder.verification.status != 'incomplete') {
            this.redirectToPage('Order');
        } else {
            let incompleteItem = false;
            this.orderItems.every(item => {
                if (item.orderItem && item.orderItem.parent_order_product_id) {
                    return true; // this item is children of a bundle item. No need to check further
                }
                this.scannedQtyBySku[item.orderItem.sku] = 0;
                this.itemsQtyBySku[item.orderItem.sku] =
                    item.orderItem.quantity;
                if (item.status == 'incomplete') {
                    incompleteItem = true;
                    return false;
                }
                return true;
            });
            if (incompleteItem == true) {
                this.redirectToPage('Pull');
            }
        }
        this.$store.dispatch('updateOrder', {
            orderId: this.$route.params.id,
            data: {
                assigned: null
            }
        });

        this.$root.$dialogLoader.hide();
        this.showCustomerServiceNote();
    },
    methods: {
        redirectToPage(page, params = {}) {
            this.$router.push({
                name: page,
                params: params
            });
        },

        async verifyOrder() {
            if (
                await this.$root.$confirmDialog.open(
                    null,
                    'The order will be marked as verified.',
                    {}
                )
            ) {
                let hasOutOfStockItem = false;
                this.currentOrder.verification.items.every(item => {
                    if (item.status == 'out-of-stock') {
                        hasOutOfStockItem = true;
                        return false;
                    }
                    return true;
                });
                if (hasOutOfStockItem == true) {
                    this.$store.dispatch('updateOrder', {
                        orderId: this.orderId,
                        data: {
                            internalStatus: 'needs-restock',
                            assigned: null
                        }
                    });
                    data.orderHistory.save({
                        orderId: this.orderId,
                        action: 'order-needs-restock',
                        description: 'One or more order items are out-of-stock'
                    });
                } else {
                    this.$store.dispatch('updateOrder', {
                        orderId: this.orderId,
                        data: {
                            internalStatus: 'verified',
                            assigned: null
                        }
                    });
                    data.orderHistory.save({
                        orderId: this.orderId,
                        action: 'verified',
                        description: 'Order has been verified.'
                    });
                }
                this.redirectToPage('Scan');
            }
        },

        printAndPack() {
            this.$store.dispatch('updateOrder', {
                orderId: this.orderId,
                data: {
                    internalStatus: 'needs-shipped',
                    assigned: null
                }
            });
            data.orderHistory.save({
                orderId: this.currentOrder.order.id,
                action: 'ready-to-ship',
                description: 'Order is ready to be shipped.'
            });
            this.redirectToPage('Scan');
        },

        nextOrder() {
            this.$store.dispatch('updateOrder', {
                orderId: this.orderId,
                data: {
                    assigned: null
                }
            });
            this.$store.dispatch('showNextUnassignedOrder', 0).then(
                response => {
                    this.$router.push({
                        name: 'Order',
                        params: {
                            order_type: 'unassigned',
                            show_next_order_button: response.lastOrder
                        }
                    });
                },
                error => {
                    console.error(error.message);
                    this.alert_message =
                        'There was an error fetching the next order';
                    this.show_alert = true;
                }
            );
        },

        setShipDate(date) {
            this.$store.dispatch('updateOrder', {
                orderId: this.orderId,
                data: {
                    internalStatus: 'ship-later',
                    assigned: null,
                    delay: {
                        date: date,
                        user: this.currentUser.uid
                    }
                }
            });
            data.orderHistory.save({
                orderId: this.orderId,
                action: 'ship-later',
                description:
                    'Set shipping date to ' +
                    date +
                    ' by ' +
                    this.currentUser.displayName
            });
        },

        scan(response) {
            this.alert_message = '';
            this.show_alert = false;
            const itemsSkuByUpc = this.itemsSkuByUpc;
            if (
                Object.prototype.hasOwnProperty.call(
                    itemsSkuByUpc,
                    response.input
                )
            ) {
                ++this.scannedQtyBySku[itemsSkuByUpc[response.input]];
                this.scannedItem.push(itemsSkuByUpc[response.input]);
            } else {
                this.alert_message =
                    'The scanned input did not match any item in the order.';
                this.show_alert = true;
            }
        },

        async showCustomerServiceNote() {
            const self = this;
            if (
                !data.orders.shouldOrderShowCustomerServiceNote(
                    self.currentOrder.order.id
                )
            ) {
                self.$root.$orderGiftMessage.show(self.currentOrder);
                return false;
            }
            let serviceNote = '';
            if (self.currentOrder.customer_service_note) {
                serviceNote = self.currentOrder.customer_service_note;
            }

            let customerNotes = '';
            const snapshot = await data.customerNotes.getCustomerNotes(
                self.currentOrder.order.customer_email
            );
            if (snapshot.exists && snapshot.data().notes !== '') {
                customerNotes = snapshot.data().notes;
            }

            if (serviceNote === '' && customerNotes === '') {
                self.$root.$orderGiftMessage.show(self.currentOrder);
                return false;
            }
            if (
                await self.$root.$orderCustomerServiceNote.show(
                    self.currentOrder.order.id,
                    serviceNote,
                    customerNotes
                )
            ) {
                self.$root.$orderGiftMessage.show(self.currentOrder);
            }
        },

        getNonGiftBundleItemsIds(orderItems) {
            const self = this;
            let nonGiftBundleItemsIds = [];
            orderItems.forEach(item => {
                if (
                    Object.prototype.hasOwnProperty.call(
                        self.currentOrder.bundle_items,
                        item.orderItem.id
                    ) &&
                    !item.orderItem.sku.startsWith('G')
                ) {
                    nonGiftBundleItemsIds.push(item.orderItem.id);
                }
            });
            return nonGiftBundleItemsIds;
        }
    }
};
</script>
