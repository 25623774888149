<template>
    <v-row no-gutters align="center">
        <v-col>
            <v-card class="order-top" elevation="0">
                <v-alert
                    v-model="show_alert"
                    color="secondary"
                    border="left"
                    colored-border
                    type="warning"
                    elevation="2"
                    dismissible
                >
                    {{ alert_message }}
                </v-alert>
                <v-card-text
                    class="text-center"
                    v-if="currentOrder && currentOrder.order"
                >
                    <h3 class="subtitle">Order Number</h3>
                    <h2 class="title">{{ currentOrder.order.id }}</h2>
                </v-card-text>
                <v-card
                    elevation="0"
                    class="mb-4"
                    v-if="currentOrder && currentOrder.picking_units"
                >
                    <v-card-text class="text-center">
                        <h2 class="subtitle">Units to Pick</h2>
                        <h1 class="title">
                            Single {{ currentOrder.picking_units.single }}
                        </h1>
                        <h1 class="title">
                            Cases {{ currentOrder.picking_units.case }}
                        </h1>
                    </v-card-text>
                </v-card>
                <v-card
                    elevation="0"
                    class="mb-4"
                    v-else-if="currentOrder && currentOrder.itemCount"
                >
                    <v-card-text class="text-center">
                        <h2 class="subtitle">Units to Pick</h2>
                        <h1 class="title">{{ currentOrder.itemCount }}</h1>
                    </v-card-text>
                </v-card>
                <v-card-text
                    class="text-center"
                    v-if="currentOrder && currentOrder.items"
                >
                    <h2 class="subtitle">Unique Items</h2>
                    <h2 class="title">{{ currentOrder.items.length }}</h2>
                </v-card-text>
                <v-btn
                    class="orderPrev"
                    dark
                    color="secondary"
                    elevation="2"
                    fab
                    v-if="!isFirstOrder"
                    @click="showPrevOrder()"
                >
                    <v-icon>fa-chevron-left</v-icon>
                </v-btn>
                <v-btn
                    class="orderNext"
                    dark
                    color="secondary"
                    elevation="2"
                    fab
                    v-if="show_next_order_button && !isLastOrder"
                    @click="showNextOrder()"
                >
                    <v-icon>fa-chevron-right</v-icon>
                </v-btn>
                <v-card elevation="0" class="mb-4">
                    <v-card-text class="text-center">
                        <v-row class="text-left">
                            <v-simple-table
                                class="fullwidth"
                                dense
                                s
                                v-if="currentOrder && currentOrder.order"
                            >
                                <template v-slot:default>
                                    <tbody>
                                        <tr
                                            v-if="
                                                currentOrder.is_perishable &&
                                                    currentOrder.is_perishable ===
                                                        true
                                            "
                                        >
                                            <td>Perishable</td>
                                            <td>Yes</td>
                                        </tr>
                                        <tr>
                                            <td>Status</td>
                                            <td>
                                                <v-chip color="primary">
                                                    {{
                                                        currentOrder.internalStatus
                                                    }}
                                                </v-chip>
                                            </td>
                                        </tr>
                                        <tr v-if="currentOrder.verification">
                                            <td>Cart</td>
                                            <td>
                                                {{
                                                    currentOrder.verification
                                                        .cartId
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Assigned to</td>
                                            <td>{{ orderAssignedTo }}</td>
                                        </tr>
                                        <tr
                                            v-if="
                                                currentOrder.delay &&
                                                    currentOrder.delay.date
                                            "
                                        >
                                            <td>Delayed Shipping until</td>
                                            <td>
                                                {{ currentOrder.delay.date }} by
                                                <v-chip small>{{
                                                    getOrderDelayedByUser
                                                }}</v-chip>
                                            </td>
                                        </tr>
                                        <tr v-if="giftItemsName.length">
                                            <td>
                                                Gift Items
                                            </td>
                                            <td>
                                                <li
                                                    v-for="(itemName,
                                                    index) in giftItemsName"
                                                    :key="index"
                                                >
                                                    {{ itemName }}
                                                </li>
                                            </td>
                                        </tr>
                                        <tr
                                            v-if="getShipLaterDate != ''"
                                            class="highlight"
                                        >
                                            <td>Ship Later Date</td>
                                            <td>
                                                <h3>{{ getShipLaterDate }}</h3>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-row>
                        <v-row>
                            <v-col>
                                <div class="my-2">
                                    <v-btn
                                        block
                                        color="secondary"
                                        dark
                                        elevation="0"
                                        router
                                        v-if="
                                            currentOrder && currentOrder.order
                                        "
                                        :to="'/pull/' + currentOrder.order.id"
                                    >
                                        <v-icon small class="mr-2"
                                            >fa-barcode</v-icon
                                        >
                                        Scan Cart and Pull
                                    </v-btn>
                                </div>
                                <div class="my-2">
                                    <v-btn
                                        block
                                        color="secondary"
                                        dark
                                        elevation="0"
                                        @click.stop="showUsers = true"
                                    >
                                        <v-icon small class="mr-2"
                                            >fa-user-tag</v-icon
                                        >
                                        Assign to User
                                    </v-btn>
                                </div>
                                <div class="my-2">
                                    <v-btn
                                        block
                                        color="secondary"
                                        dark
                                        elevation="0"
                                        @click="unlockOrder"
                                        v-show="canUnlock"
                                    >
                                        <v-icon small class="mr-2"
                                            >fa-clock</v-icon
                                        >
                                        Unlock Order
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-col>
                                <div class="my-2">
                                    <v-btn
                                        block
                                        color="secondary"
                                        dark
                                        elevation="0"
                                        @click="showDatePicker = true"
                                    >
                                        <v-icon small class="mr-2"
                                            >fa-clock</v-icon
                                        >
                                        Ship Later
                                    </v-btn>
                                </div>
                                <div class="my-2">
                                    <v-btn
                                        block
                                        color="secondary"
                                        dark
                                        elevation="0"
                                        @click="showOrderHistory = true"
                                    >
                                        <v-icon small class="mr-2"
                                            >fa-comments</v-icon
                                        >
                                        View Order History
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <Users
                    v-model="showUsers"
                    @showNextOrder="showNextOrder"
                ></Users>
                <DatePicker
                    v-model="showDatePicker"
                    @selectedDate="setShipDate"
                ></DatePicker>
                <div v-if="currentOrder && currentOrder.order">
                    <OrderHistory
                        v-model="showOrderHistory"
                        :orderId="currentOrder.order.id"
                    ></OrderHistory>
                </div>
                <div v-if="currentOrder && currentOrder.order">
                    <ReAssignOrder
                        v-model="showPromptReassign"
                        :orderId="currentOrder.order.id"
                        :assignedTo="orderAssignedTo"
                    ></ReAssignOrder>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>
<style scoped>
.highlight {
    background-color: #fff300;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import Users from '@/components/Users';
import DatePicker from '@/components/DatePicker';
import * as data from '@/data/index';
import OrderHistory from '@/components/OrderHistory';
import ReAssignOrder from '@/components/ReAssignOrder';

export default {
    name: 'Order',
    props: {
        order_type: {
            type: String
            //required: true
        },
        show_next_order_button: {
            type: Boolean,
            default: false
        },
        show_alert: {
            default: false
        },
        alert_message: {
            default: ''
        },
        prompt_reassign: {
            type: Boolean,
            default: false
        },
        show_customer_service_note: {
            type: Boolean,
            default: true
        }
    },
    data: () => {
        return {
            order: {},
            offset: 0,
            showUsers: false,
            isLastOrder: false,
            isFirstOrder: true,
            showDatePicker: false,
            showOrderHistory: false,
            showPromptReassign: false
        };
    },
    watch: {
        currentOrder(newValue, oldValue) {
            if (oldValue === null && newValue?.order) {
                this.showCustomerServiceNote();
                this.checkIfOrderIsLocked();
            }
        }
    },
    mounted: function() {
        this.showPromptReassign = this.prompt_reassign;
        if (this.currentOrder?.order) {
            this.showCustomerServiceNote();
            this.checkIfOrderIsLocked();
        }
    },
    components: {
        Users,
        DatePicker,
        OrderHistory,
        ReAssignOrder
    },
    computed: {
        ...mapState([
            'currentUser',
            'currentOrder',
            'users',
            'canDisplayOrderCustomerServiceNote'
        ]),
        ...mapGetters(['getUnAssignedOrdersCount', 'getAssignedOrderCount']),
        orderAssignedTo() {
            let assignedTo = '';
            this.users.every(user => {
                if (user.id == this.currentOrder.assigned) {
                    assignedTo = user.displayName;
                    return false;
                }
                return true;
            });
            return assignedTo;
        },
        getOrderDelayedByUser() {
            let delayedBy = '';
            if (this.currentOrder.delay && this.currentOrder.delay.date) {
                this.users.every(user => {
                    if (user.id == this.currentOrder.delay.user) {
                        delayedBy = user.displayName;
                        return false;
                    }
                    return true;
                });
            }
            return delayedBy;
        },
        giftItemsName() {
            let giftItemsName = [];
            if (this.currentOrder.has_bundle_product === true) {
                for (let id in this.currentOrder.bundle_items) {
                    if (
                        Object.prototype.hasOwnProperty.call(
                            this.currentOrder.bundle_items,
                            id
                        )
                    ) {
                        giftItemsName.push(this.currentOrder.bundle_items[id]);
                    }
                }
            }
            return giftItemsName;
        },
        getShipLaterDate() {
            return data.orders.getOrderShipLaterDate(this.currentOrder);
        },
        canUnlock() {
            return (
                this.currentOrder.is_locked &&
                this.currentOrder.is_locked === true &&
                this.currentUser.role.includes('ADMIN')
            );
        }
    },
    methods: {
        showNextOrder() {
            this.$store.dispatch('assignOrderQueue', this.order_type);
            let dispatchMethod =
                this.order_type === 'assigned'
                    ? 'showNextAssignedOrder'
                    : 'showNextUnassignedOrder';
            this.offset = this.offset + 1;
            this.$store.dispatch(dispatchMethod, this.offset).then(
                response => {
                    this.isLastOrder = response.lastOrder;
                    this.isFirstOrder = response.firstOrder;
                },
                error => {
                    console.log(error.message);
                }
            );
        },
        showPrevOrder() {
            this.$store.dispatch('assignOrderQueue', this.order_type);
            let dispatchMethod =
                this.order_type === 'assigned'
                    ? 'showNextAssignedOrder'
                    : 'showNextUnassignedOrder';
            this.offset = this.offset - 1;
            this.$store.dispatch(dispatchMethod, this.offset).then(
                response => {
                    this.isLastOrder = response.lastOrder;
                    this.isFirstOrder = response.firstOrder;
                },
                error => {
                    console.log(error.message);
                }
            );
        },
        setShipDate(date) {
            this.$store.dispatch('updateOrder', {
                orderId: this.currentOrder.order.id,
                data: {
                    internalStatus: 'ship-later',
                    assigned: null,
                    delay: {
                        date: date,
                        user: this.currentUser.uid
                    }
                }
            });
            data.orderHistory.save({
                orderId: this.currentOrder.order.id,
                action: 'ship-later',
                description:
                    'Set shipping date to ' +
                    date +
                    ' by ' +
                    this.currentUser.displayName
            });
        },
        async showCustomerServiceNote() {
            if (this.show_customer_service_note === false) {
                return false;
            }
            if (
                !data.orders.shouldOrderShowCustomerServiceNote(
                    this.currentOrder.order.id
                )
            ) {
                return false;
            }
            let serviceNote = '';
            if (this.currentOrder.customer_service_note) {
                serviceNote = this.currentOrder.customer_service_note;
            }

            let customerNotes = '';
            const snapshot = await data.customerNotes.getCustomerNotes(
                this.currentOrder.order.customer_email
            );
            if (snapshot.exists && snapshot.data().notes !== '') {
                customerNotes = snapshot.data().notes;
            }

            if (serviceNote === '' && customerNotes === '') {
                return false;
            }
            this.$root.$orderCustomerServiceNote.show(
                this.currentOrder.order.id,
                serviceNote,
                customerNotes
            );
        },
        checkIfOrderIsLocked() {
            if (
                this.currentOrder.is_locked &&
                this.currentOrder.is_locked === true
            ) {
                this.$root.$confirmDialog.open(
                    null,
                    'This order is locked',
                    {}
                );
            }
        },
        async unlockOrder() {
            if (
                await this.$root.$confirmDialog.open(
                    null,
                    'Are you sure you want to unlock this order?',
                    {}
                )
            ) {
                this.$root.$dialogLoader.show('Unlocking Order', {});
                this.$store
                    .dispatch('updateOrder', {
                        orderId: this.currentOrder.order.id,
                        data: {
                            is_locked: false
                        }
                    })
                    .then(() => {
                        data.orderHistory.save({
                            orderId: this.currentOrder.order.id,
                            action: 'unlock-order',
                            description:
                                'Order has been unlocked by ' +
                                this.currentUser.displayName
                        });
                        this.$root.$dialogLoader.hide();
                    });
            }
        }
    },
    created() {
        this.$store.dispatch('assignOrderQueue', this.order_type);
        if (this.order_type === 'assigned' && this.getAssignedOrderCount == 1) {
            this.isLastOrder = true;
            this.isFirstOrder = true;
        }
        if (
            this.order_type === 'unassigned' &&
            this.getUnAssignedOrdersCount == 1
        ) {
            this.isLastOrder = true;
            this.isFirstOrder = true;
        }
    }
};
</script>
